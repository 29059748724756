import axios from "axios";
import getAdminApiPath from "@hornet-api/getAdminApiPath";
import {IInsurance} from "@interfaces/insurance";
import {convertFormDataToServerData, IInsuranceForm} from "@admin-ui/pages/InsurancePage/components/constant";
import convertJsonToFormData from "@common/utils/form/convertJsonToFormData";

const getInsuranceList = async () => {
  const url = `${getAdminApiPath()}/insurance`;
  const res = await axios.get<IInsurance[]>(url);
  return res.data;
};

const getInsuranceListForCompany = async (id: number | string) => {
  const url = `${getAdminApiPath()}/insurance/company/${id}`;
  const res = await axios.get<IInsurance[]>(url);
  return res.data;
};

const getInsuranceListForLoan = async (aliasId: string) => {
  const url = `${getAdminApiPath()}/insurance/loan/${aliasId}`;
  const res = await axios.get<IInsurance[]>(url);
  return res.data;
};

const getInsuranceListForProperty = async (id: number) => {
  const url = `${getAdminApiPath()}/insurance/property/${id}`;
  const res = await axios.get<IInsurance[]>(url);
  return res.data;
};

const saveInsurance = async (formValue: IInsuranceForm) => {
  const url = `${getAdminApiPath()}/insurance`;

  const formData = convertJsonToFormData(convertFormDataToServerData(formValue));
  const res = await axios.post(url, formData);
  return res.data;
}

const updateInsurance = async (formValue: IInsuranceForm) => {
  const url = `${getAdminApiPath()}/insurance/${formValue.id}`;

  const formData = convertJsonToFormData(convertFormDataToServerData(formValue));
  const res = await axios.put(url, formData);
  return res.data;
}

const deleteInsurance = async (id: string | number) => {
  const url = `${getAdminApiPath()}/insurance/${id}`;
  return axios.delete(url);
};

const getInsuranceById = async (id: string | number) => {
  const url = `${getAdminApiPath()}/insurance/${id}`;
  const res = await axios.get<IInsurance>(url);
  return res.data;
};

const addInsuranceDocuments = (id: number | string, documents: File[]) => {
  const formData = new FormData();
  documents.forEach(document => formData.append('documents', document))

  const url = `${getAdminApiPath()}/insurance/${id}/document`;
  return axios.post(url, formData);
}

const deleteInsuranceDocument = async (id: number | string, documentId: number) => {
  const url = `${getAdminApiPath()}/insurance/${id}/document/${documentId}`;
  return axios.delete(url);
};

export {
  getInsuranceList,
  saveInsurance,
  getInsuranceById,
  deleteInsurance,
  addInsuranceDocuments,
  deleteInsuranceDocument,
  updateInsurance,
  getInsuranceListForCompany,
  getInsuranceListForLoan,
  getInsuranceListForProperty
}
