import axios from 'axios';
import getAdminApiPath from '../getAdminApiPath';
import {AddEditCompanyServer, Company, CompanyUpdateData} from '@interfaces/Company';

/**
 * Updates a company
 *
 * CAUTION: It expects 'ein'. If it's not present, it'll be deleted from the company
 *
 * @param entityId
 * @param data
 * @see patchCompany
 */
const updateCompany = async (entityId: number, data: AddEditCompanyServer) => {
    const url = `${getAdminApiPath()}/entity/${entityId}`;
    const response = await axios.put(url, data);
    return response.data as Company;
};

/**
 * Updates the company
 *
 * Can be used to patch
 * @param entityId
 * @param data
 */
export const patchCompany = async (entityId: number, data: CompanyUpdateData) => {
    const url = `${getAdminApiPath()}/entity/${entityId}`;
    const response = await axios.patch(url, data);
    return response.data as AddEditCompanyServer;
};

export default updateCompany;