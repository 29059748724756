import axios from 'axios';
import getAdminApiPath from '../getAdminApiPath';
import {AddEditCompanyServer, Company} from '@interfaces/Company';

const createdCompany = async (data: AddEditCompanyServer) => {
  const url = `${getAdminApiPath()}/entity`;
  const response = await axios.post(url, data);
  return response.data as Company;
};

export default createdCompany;