export const pluralize = (word: string, count: number) => {
  if (count === 1) {
    return word;
  }

  const irregularPlurals: { [p: string]: string } = {
    'child': 'children',
    'person': 'people',
    'mouse': 'mice',
    'goose': 'geese',
    'man': 'men',
    'woman': 'women',
    'tooth': 'teeth',
    'foot': 'feet',
    'cactus': 'cacti',
    'focus': 'foci',
    'fungus': 'fungi',
    'nucleus': 'nuclei',
    'syllabus': 'syllabi',
    'analysis': 'analyses',
    'diagnosis': 'diagnoses',
    'thesis': 'theses',
    'crisis': 'crises',
    'phenomenon': 'phenomena',
    'criterion': 'criteria',
    'datum': 'data'
  };

  if (irregularPlurals[word]) {
    return irregularPlurals[word];
  }

  const uncountableNouns = [
    'fish', 'sheep', 'deer', 'species', 'aircraft', 'moose', 'people'
  ];

  if (uncountableNouns.includes(word)) {
    return word;
  }

  const lastChar = word.slice(-1);
  const lastTwoChars = word.slice(-2);
  const lastThreeChars = word.slice(-3);

  if (lastThreeChars === 'man') {
    return word.slice(0, -3) + 'men';
  } else if (lastTwoChars === 'is') {
    return word.slice(0, -2) + 'es';
  } else if (lastTwoChars === 'us') {
    return word.slice(0, -2) + 'i';
  } else if (lastTwoChars === 'on') {
    return word.slice(0, -2) + 'a';
  } else if (lastTwoChars === 'um') {
    return word.slice(0, -2) + 'a';
  } else if (lastChar === 'y' && !['a', 'e', 'i', 'o', 'u'].includes(word.slice(-2, -1))) {
    return word.slice(0, -1) + 'ies';
  } else if (lastChar === 'f') {
    return word.slice(0, -1) + 'ves';
  } else if (lastTwoChars === 'fe') {
    return word.slice(0, -2) + 'ves';
  } else if (['s', 'x', 'z', 'ch', 'sh'].includes(lastChar) || ['s', 'x', 'z', 'ch', 'sh'].includes(lastTwoChars)) {
    return word + 'es';
  } else {
    return word + 's';
  }
}

/**
 * Extracts all numeric characters from a given string, concatenates them,
 * and returns the resulting number.
 *
 * @param {string} inputText - The input string from which numbers are to be extracted.
 *
 */
export const extractNumber = (inputText: string | null): number | null => {
  if (!inputText) {
    return null;
  }
  const matches = inputText.match(/\d+/g);
  const concatenatedNumbers = matches ? matches.join('') : '';
  return Number(concatenatedNumbers);
}
