import React, {useEffect, useState} from 'react';
import ApplicationLoading from '@components/ApplicationLoading';
import {getBaseUri} from '@common/baseUri';
import {getAccessToken} from '@common/token';
import {Route, Switch, useLocation} from 'react-router-dom';
import {initialize, isInitializedState} from '@admin/globalState';
import AdminFramer from '../ADMIN/AdminFramer';
import AdminSidebar from '../ADMIN/shared/AdminSidebar';
import {sidebarExpandedState} from '@admin/shared/AdminSidebar/sidebarStore';
import ContactPage, {ContactListPage} from '@admin-ui/pages/ContactPage';
import AdminLoadingOverlay from '@admin-ui/components/AdminLoadingOverlay';

import * as Auth from '@server-api/index';
import LogoutView from "@legacy/views/auth/LogoutView";
import BorrowerProjectApplicationsList from "@admin-ui/pages/BorrowerProjectApplicationsList";
import BorrowerProjectApplication from "@admin-ui/pages/BorrowerProjectApplication";
import LoanPage from "@admin-ui/pages/LoanPage";
import PropertyPage from "@admin-ui/pages/PropertyPage";
import CompanyPage from "@admin-ui/pages/CompanyPage";
import EmailTemplatePage from "@admin-ui/pages/EmailTemplatePage";
import AchSchedulePage from "@admin-ui/pages/AchSchedulePage";
import LoanShowPage from "@admin-ui/pages/LoanShowPage";
import FinancialInformationPage from "@admin-ui/pages/FinancialInformationPage";
import QuarterlyReturnPage from "@admin-ui/pages/QuarterlyReturnPage";
import ReportPage from "@admin-ui/pages/ReportPage";
import SmsWhitelistPage from "@admin-ui/pages/SmsWhitelistPage";
import ContentPage from "@admin-ui/pages/ContentPage";
import LeadSourcePage from "@admin-ui/pages/LeadSourcePage";
import SystemConfigPage from "@admin-ui/pages/SystemConfigPage";
import SuperiorLienPage from "@admin-ui/pages/LoanPage/SuperiorLienPage";
import IntegrationPage from "@admin-ui/pages/IntegrationPage";
import MessageGroupPage from "@admin-ui/pages/MessageGroupPage";
import MessagePage from "@admin-ui/pages/MessagePage";
import InvestorPage from "@admin-ui/pages/InvestorPage";
import TaskTemplates from "@admin-ui/pages/TaskTemplates";
import tokenToSession from "@hornet-api/tokenToSession";
import AdvancedSettingsPage from "@admin-ui/pages/AdvancedSettingsPage";
import TasksPage from "@admin-ui/pages/TasksPage/TasksPage";
import AchTransactionPage from "@admin-ui/pages/AchTransactionPage";
import FundTransactionPage from "@admin-ui/pages/FundTransactionPage";
import InvestorShowPage from "@admin-ui/pages/InvestorPage/InvestorShowPage";
import AmbassadorPage from "@admin-ui/pages/AmbassadorPage/AmbassadorPage";
import RedirectLocalStorage from "@routes/RedirectLocalStorage";
import ReAuthConnectionModal from "@admin-ui/components/externalOauth/ReAuthConnectionModal";
import ProfilePage from "@admin-ui/pages/profile/ProfilePage";
import EntityPage from "@admin-ui/pages/EntityPage";
import LineItemCategoryPage from "@admin-ui/pages/LineItemCategoryPage";
import BudgetPage from "src/admin-ui/pages/Budget/BudgetPage";
import DrawRequestPage from "@admin-ui/pages/Budget/DrawRequestPage";
import FeaturesPage from "@admin-ui/pages/FeaturesPage";
import LoanFundingSourcePage from "@admin-ui/pages/LoanFundingSourcePage";
import TitleEndorsementPage from "@admin-ui/pages/TitleEndorsementsPage";
import DefNotificationQueueListModal from "@admin-ui/pages/EmailTemplatePage/components/DefNotificationQueueListModal";
import EmailLogPage from "@admin-ui/pages/EmailLogPage";
import Vendor from "@admin-ui/pages/Vendor";
import TFALogPage from "src/admin-ui/pages/TFALogPage";
import ReleaseNotesPage from "@admin-ui/pages/ReleaseNotesPage";
import VoiceCallLogPage from "@admin-ui/pages/VoiceCallLogPage";
import FundImportPage from "@admin-ui/pages/BulkImport/FundImportPage";
import EscrowImportPage from "@admin-ui/pages/BulkImport/EscrowImportPage";
import SageLoanServicingImportPage from "@admin-ui/pages/BulkImport/SageLoanServicingImportPage";
import GlobalSearchPage from "@admin-ui/pages/GlobalSearchPage";
import MergeDocumentPage from "@admin-ui/pages/MergeDocumentPage";
import ShowPropertyModal from "@admin-ui/pages/PropertyPage/show/ShowPropertyModal";
import UploaderPage from "@admin-ui/pages/UploaderPage/UploaderPage";
import AdminDashboard from "@admin-ui/pages/AdminDashboard";
import LoanParticipationAgreementPage from "@admin-ui/pages/LoanParticipationAgreement";
import ApplicationConfigPage from "@admin-ui/pages/ApplicationConfigPage";
import GlobalSearchModal from "@admin-ui/pages/SearchPage/GlobalSearchModal";
import OneNotaryPage from "@admin-ui/pages/OneNotaryPage";
import BorrowerInvoicesPage from "@admin-ui/pages/Statements/BorrowerInvoicesPage";
import {FcmMessagingComponent} from "@components/FcmMessagingComponent";
import {APIProvider} from "@vis.gl/react-google-maps";
import {useRecoilValue} from "recoil";
import configurationAtom from "@state/recoil/configurationAtom";
import {ChatTypesEnum} from "@interfaces/GeneratedEnums";
import NotFoundPage from "@admin-ui/pages/NotFound/NotFoundPage";
import InsurancePage from "@admin-ui/pages/InsurancePage";
import RouteGuard from "@routes/RouteGuard";

const RoutesAdmin = () => {
  const useLegacyAdmin = false;
  const location = useLocation();
  const isInitialized = isInitializedState.useValue();
  const sidebarExpanded = sidebarExpandedState.useValue();
  const [gotSession, setGotSession] = useState(false);
  const configuration = useRecoilValue(configurationAtom);

  // add the class to the body to make admin styles work
  useEffect(() => {
    document.body.classList.add('role-admin');
    return () => {
      document.body.classList.remove('role-admin');
    }
  }, []);

  useEffect(() => {
    if (useLegacyAdmin) {
      const redirectUrl = `${getBaseUri()}login/tokenToSessionAuth?access_token=${getAccessToken()}`;
      // log out the user here and log the into the legacy admin
      Auth.signoffUser().then(() => {
        // do redirect
        window.location.href = redirectUrl;
      })
    } else {
      initialize();
      tokenToSession().then(() => {
        setGotSession(true);
      });
      // redirect to iframe to get session
      // const redirectUrl = `/login/tokenToSessionAuth?access_token=${getAccessToken()}`;
      // history.push(redirectUrl);
    }
  }, []);

  if (useLegacyAdmin || !isInitialized || !gotSession) {
    return <ApplicationLoading />
  }

  const validateNumericId = (params: Record<string, string | undefined>) => {
    const {id} = params;
    return !id || !isNaN(Number(id));
  };

  const validateActionAndId = (params: Record<string, string | undefined>): boolean => {
    const {action} = params;

    return validateNumericId(params) && (!action || action.trim() !== '');
  };


  // the new admin goes here
  return (<>
    <AdminSidebar />
    <GlobalSearchModal/>
    <RedirectLocalStorage />
    <div
      className={sidebarExpanded ? 'main-container' : 'main-container collapsed'}
      style={{
        padding: 0,
        overflow: 'hidden'
      }}
    >
      <APIProvider apiKey={configuration?.google_maps_key || ''} libraries={['places', 'marker', 'maps']}>
        <Switch location={location}>
          <Route path="/logout" exact><LogoutView/></Route>
          <Route path="/profile" exact><ProfilePage/></Route>

          {/* Contact page */}
          {/* <Route path="/legacy/contact/show/:contactId"><NewUiReRouter /></Route> */}
          <Route path="/contact/:action(create)?" exact><ContactListPage/></Route>
          <Route path="/contact/show/:contactId/:tab?" exact><ContactPage/></Route>
          <Route path="/loan/:action(create)?" exact><LoanPage/></Route>
          <Route path="/loan/show/:loanAliasId" exact><LoanShowPage/></Route>
          <Route path="/holiday" exact><AchSchedulePage/></Route>
          <Route path="/admin/transactions/:action?/:id?" exact><AchTransactionPage/></Route>
          <Route path="/investment/:action?/:id?" exact><FundTransactionPage/></Route>
          <Route path="/entity/:action(create)?" exact><CompanyPage/></Route>
          <Route path="/entity/show/:entityId/:tab?" exact><EntityPage/></Route>

          <Route path="/property/show/:id?" exact><ShowPropertyModal/></Route>
          <Route path="/property/:action?/:id?" exact><PropertyPage/></Route>
          <Route path="/advancedSettings/:tab?/:action?/:id?" exact><AdvancedSettingsPage/></Route>
          <Route path="/emailTemplate/:action?/:id?" exact><EmailTemplatePage/></Route>
          <Route path="/quarterlyReturn/:action?/:id?" exact><QuarterlyReturnPage/></Route>
          <Route path="/report/:action?/:id?" exact><ReportPage/></Route>
          <Route path="/smsWhitelist/:action?/:id?" exact><SmsWhitelistPage/></Route>
          <Route path="/content/:action?/:id?" exact><ContentPage/></Route>
          <Route path="/releaseNotes/:action?/:id?" exact><ReleaseNotesPage/></Route>
          <Route path="/lineItemCategory/:action?/:id?" exact><LineItemCategoryPage/></Route>
          <Route path="/vendor" exact><Vendor/></Route>
          <Route path="/budgets/:id?" exact><BudgetPage/></Route>
          <Route path="/drawRequest/:id?" exact><DrawRequestPage/></Route>
          <Route path="/integration" exact><IntegrationPage/></Route>
          <Route path="/investor/" exact><InvestorPage/></Route>
          <Route path="/features/" exact><FeaturesPage/></Route>
          <Route path="/investor/show/:investorId" exact><InvestorShowPage/></Route>
          <Route path="/messageGroup/:action?/:id?" exact><MessageGroupPage/></Route>
          <Route path="/message/composeEmail" exact><MessagePage/></Route>
          <Route path="/loan/superiorLiens" exact><SuperiorLienPage/></Route>
          <Route path="/leadSource/:action?/:id?" exact><LeadSourcePage/></Route>
          <Route path="/mergeDocument/:action?/:id?" exact><MergeDocumentPage/></Route>
          <Route path="/loanFundingSources/:action?/:id?" exact><LoanFundingSourcePage/></Route>
          <Route path="/titleEndorsement/:action?/:id?" exact><TitleEndorsementPage/></Route>
          <Route path="/systemConfig/:action?/:id?" exact><SystemConfigPage/></Route>
          <Route path="/applicationConfig" exact><ApplicationConfigPage/></Route>
          <Route path="/financialInformation/:action?/:id?" exact><FinancialInformationPage/></Route>
          <Route path="/taskTemplates" exact><TaskTemplates/></Route>
          <Route path="/tasks/:action?/:id?" exact><TasksPage/></Route>
          <Route path="/ambassador" exact><AmbassadorPage/></Route>
          <Route path="/emailLog/:action?/:id?" exact><EmailLogPage/></Route>
          <Route path="/voiceCallLog/:action?/:id?" exact><VoiceCallLogPage/></Route>
          <Route path="/bulkImport/fundImport" exact><FundImportPage/></Route>
          <Route path="/bulkImport/escrowImport" exact><EscrowImportPage/></Route>
          <Route path="/bulkImport/sageLoanServicing" exact><SageLoanServicingImportPage/></Route>
          <Route path="/2FALog" exact><TFALogPage/></Route>
          <Route path="/globalSearch" exact><GlobalSearchPage/></Route>
          <Route path="/uploader/:action?/:id?" exact>
            <RouteGuard
              Component={UploaderPage}
              validateParams={validateActionAndId}
              redirectTo="/uploader"
              alertMessage="Invalid action or ID. Redirecting to the uploader page."
            />
          </Route>
          <Route path="/loanParticipationAgreement/:action?/:id?" exact>
            <RouteGuard
              Component={LoanParticipationAgreementPage}
              validateParams={validateActionAndId}
              redirectTo="/loanParticipationAgreement"
              alertMessage="Invalid action or ID. Redirecting to the LPA list page."
            />
          </Route>
          <Route path="/notary/:sessionId?" exact><OneNotaryPage/></Route>
          <Route path="/insurance/:action?/:id?" exact>
            <RouteGuard
              Component={InsurancePage}
              validateParams={validateActionAndId}
              redirectTo="/insurance"
              alertMessage="Invalid ID. Redirecting to the Insurance List page."
            />
          </Route>
          {/*Borrower*/}
          <Route path="/borrower/application" exact><BorrowerProjectApplicationsList/></Route>
          <Route path="/borrower/application/:applicationId/:snapshotId?" exact><BorrowerProjectApplication/></Route>

          {/* Statements */}
          <Route path="/statements/borrowerInvoices" exact><BorrowerInvoicesPage/></Route>

          {/* Handles /taskChat/$id and /contactChat/$id (maybe /loanChat/$id in the future) */}
          <Route path={[`/:sidebarType(${Object.values(ChatTypesEnum).join('|')})?/:itemId?`, "/admin/index"]}
                 exact><AdminDashboard/></Route>

          {/* Catch all for re-router */}
          <Route path={["/legacy/*"]} exact><AdminFramer/></Route>
          <Route path="*" exact><NotFoundPage/></Route>
        </Switch>
      </APIProvider>
      <ReAuthConnectionModal/>
      <AdminLoadingOverlay/>
      <DefNotificationQueueListModal/>
      <FcmMessagingComponent/>
    </div>
  </>)
};

export default RoutesAdmin;
