import axios from 'axios';
import getAdminApiPath from "@hornet-api/getAdminApiPath";

const hasCompanyDrawRequests = (entityId: number | string) => {

  const url = `${getAdminApiPath()}/entity/${entityId}/hasDrawRequests`;
  return axios.get<{ hasDrawRequests: boolean }>(url).then(res => res.data?.hasDrawRequests)
};

export default hasCompanyDrawRequests;
