import React, {useEffect, useMemo, useState} from "react";
import {Button, Form, Modal} from "react-bootstrap";
import {SubmitHandler, useForm} from "react-hook-form";
import {createGlobalState} from "react-global-hooks";
import {
  Date as DateField,
  FileUploadControl,
  Radios,
  SearchableSelect,
  Select,
  SmartInput,
  TextBox
} from "@components/forms/react-hook-form-bootstrap";
import {loadingRelease, loadingTrigger} from "@components/LoadingOverlay";
import {alertApiErrors} from "@common/errors";
import {
  convertServerDataToFormData,
  defaultFormValue,
  getCoverageStatus,
  IInsuranceForm,
  InsuranceCoverageStatusEnumDisplay,
  TitleInsuranceEnum
} from "@admin-ui/pages/InsurancePage/components/constant";
import {saveInsurance, updateInsurance} from "@hornet-api/insurance";
import {IInsurance} from "@interfaces/insurance";
import {InsuranceCoverageTypeEnum, InsuranceDocumentTypeEnum} from "@interfaces/GeneratedEnums";
import {PublicPrivateEnum, YesNoEnum} from "@interfaces/Enums";
import {
  globalSearchDataLoadingState,
  globalSearchDataState,
  searchableObjectToOption,
  searchableObjectToOptions
} from "@admin-ui/pages/SearchPage/constant";
import getContactPositionList from "@hornet-api/company/contactPosition/getContactPositionList";
import ContactCompanyPosition from "@interfaces/ContactCompanyPosition";
import {minNumberValidator} from "@common/utils/validators/minNumberValidator";
import {openAddEditEntityModal} from "@admin-ui/pages/EntityPage/components/AddEditEntityModal";
import {FaPlus} from "react-icons/fa";
import AddContactToEntityModal, {openAddContactToEntityModal} from "@admin-ui/components/AddContactToEntityModal";
import TitleEndorsement from "@interfaces/TitleEndorsement";
import getTitleEndorsementList from "@hornet-api/titleEndorsement/getTitleEndorsementList";


interface AddEditInsuranceModalState {
  aliasIds: string[];
  propertyIds: number[];
  insurance?: IInsurance;
  onComplete?: () => void;
  onCancel?: () => Promise<void> | void;
  onClose?: () => Promise<void> | void;
}

export const addEditInsuranceModalState = createGlobalState<AddEditInsuranceModalState | null>(null);

export const openAddEditInsuranceModal = (modalState: AddEditInsuranceModalState) => addEditInsuranceModalState.set(modalState);

const AddEditInsuranceModal = () => {
  const [modalState, setModalState] = addEditInsuranceModalState.use();
  const globalSearchData = globalSearchDataState.useValue();
  const globalSearchDataLoadingValues = globalSearchDataLoadingState.useValue();
  const [carrierAgents, setCarrierAgents] = useState<ContactCompanyPosition[]>([]);
  const [titleEndorsements, setTitleEndorsements] = useState<TitleEndorsement[]>([]);
  const {control, handleSubmit, reset, watch, setValue} = useForm<IInsuranceForm>({defaultValues: defaultFormValue});

  const titleInsurance = watch('titleInsurance');
  const coverageExpirationDate = watch('coverageExpirationDate');
  const coverageStartDate = watch('coverageStartDate');
  const carrierName = watch('carrierName');


  const propertyOptions = useMemo(() => searchableObjectToOptions(globalSearchData.PROPERTY), [globalSearchData.PROPERTY]);
  const companyOptions = useMemo(() => searchableObjectToOptions(globalSearchData.ENTITY), [globalSearchData.ENTITY]);
  const carrierAgentOptions = useMemo(() => {
    return carrierAgents.map((carrierAgent) => ({
      value: carrierAgent.id,
      label: `${carrierAgent.contact.name} - ${carrierAgent.position}`,
    }))
  }, [carrierAgents]);

  useEffect(() => {
    const t = loadingTrigger();
    getTitleEndorsementList()
      .then(setTitleEndorsements)
      .catch(alertApiErrors)
      .finally(() => loadingRelease(t));
  }, []);

  const loanOptions = useMemo(() => searchableObjectToOptions(globalSearchData.LOAN), [globalSearchData.LOAN]);
  const titleEndorsementOptions = useMemo(() => {
    return titleEndorsements.map((titleEndorsement) => ({
      value: titleEndorsement.id,
      label: titleEndorsement.title,
    }))
  }, [titleEndorsements]);

  useEffect(() => {
    if (modalState?.insurance?.carrierAgentCcpId) {
      const matchCarrierAgent = carrierAgentOptions.find(({value}) => value === modalState?.insurance?.carrierAgentCcpId);
      if (matchCarrierAgent) {
        setValue("carrierAgent", matchCarrierAgent);
      }
    }
  }, [carrierAgentOptions]);

  useEffect(() => {
    if (!modalState?.aliasIds.length) return;
    const associatedLoans = modalState.aliasIds.map((aliasId) => searchableObjectToOption(aliasId, globalSearchData.LOAN));
    setValue('associatedLoans', associatedLoans);
  }, [modalState?.aliasIds, globalSearchData.LOAN]);

  useEffect(() => {
    if (!modalState?.propertyIds.length) return;
    const coveredProperties = modalState.propertyIds.map((propertyId) => searchableObjectToOption(propertyId, globalSearchData.PROPERTY));
    setValue('coveredProperties', coveredProperties);
  }, [modalState?.propertyIds, globalSearchData.PROPERTY]);

  useEffect(() => {
    if (!modalState?.insurance) return;
    reset(convertServerDataToFormData(modalState.insurance, globalSearchData, titleEndorsementOptions));
  }, [modalState?.insurance, globalSearchData.LOAN, globalSearchData.PROPERTY, globalSearchData.ENTITY, titleEndorsementOptions]);


  useEffect(() => {
    if (titleInsurance === "DO_NOT_EXPIRE") {
      setValue('coverageExpirationDate', new Date('9999-12-31'));
    }
  }, [titleInsurance]);

  useEffect(() => {
    if (coverageStartDate && coverageExpirationDate) {
      setValue('coverageStatus', getCoverageStatus(coverageStartDate, coverageExpirationDate));
    }
  }, [coverageStartDate, coverageExpirationDate]);

  const refreshCarrierAgents = () => {
    if (typeof carrierName === "object" && carrierName?.value) {
      const t = loadingTrigger();
      getContactPositionList(carrierName.value)
        .then(setCarrierAgents)
        .catch(alertApiErrors)
        .finally(() => loadingRelease(t));
    }
  }

  useEffect(() => {
    if (typeof carrierName === "object" && carrierName?.value) {
      refreshCarrierAgents();
    } else {
      setCarrierAgents([]);
      setValue('carrierAgent', "");
    }
  }, [carrierName]);

  const handleClose = () => {
    setModalState(null);
    reset(defaultFormValue);
    modalState?.onClose?.();
  };

  const onSubmit: SubmitHandler<IInsuranceForm> = (data) => {
    const t = loadingTrigger();
    (data.id ? updateInsurance : saveInsurance)(data)
      .then(modalState?.onComplete)
      .then(handleClose)
      .catch(alertApiErrors)
      .finally(() => loadingRelease(t));
  };

  return (
    <Modal
      show={!!modalState}
      onHide={handleClose}
      id="addEditInsuranceModal"
      size={'lg'}
    >
      <Modal.Header closeButton>
        <Modal.Title>{modalState?.insurance?.id ? "Edit" : "Add"} Insurance</Modal.Title>
      </Modal.Header>
      <Form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body className="position-relative">


          <Select
            name='coverageType'
            label='Coverage Type'
            control={control}
            options={InsuranceCoverageTypeEnum}
            emptyOption="-- Please Select --"
            rules={{required: true}}
          />

          <Select
            name='documentType'
            label='Document Type'
            control={control}
            options={InsuranceDocumentTypeEnum}
            emptyOption='-- Please Select --'
            rules={{required: true}}
          />

          <SmartInput
            name='coverageAmount'
            label='Coverage Amount'
            type='currency'
            control={control}
            rules={{
              required: true,
              validate: {min: minNumberValidator(0.01, 'Please enter a valid number that is greater than 0.01')}
            }}
          />

          <DateField
            name='coverageStartDate'
            label='Coverage Start Date'
            control={control}
            rules={{required: true}}
          />

          <DateField
            name='coverageExpirationDate'
            label='Coverage Expiration Date'
            control={control}
            rules={{required: true}}
            disabled={titleInsurance === "DO_NOT_EXPIRE"}
          />

          <Select
            name='titleInsurance'
            label='Title Insurance'
            control={control}
            options={TitleInsuranceEnum}
            emptyOption='-- Please Select --'
          />


          <Select
            name='coverageStatus'
            label='Coverage Status'
            control={control}
            options={InsuranceCoverageStatusEnumDisplay}
            hideAppearanceWhenDisabled
            disabled
          />

          <SearchableSelect
            name='coveredProperties'
            label='Covered Properties'
            control={control}
            options={propertyOptions}
            isMulti
            isLoading={!globalSearchDataLoadingValues.PROPERTY}
          />

          <SearchableSelect
            name='carrierName'
            label='Carrier Name'
            control={control}
            options={companyOptions}
            rules={{required: true}}
            isLoading={!globalSearchDataLoadingValues.ENTITY}
            rightElement={(
              <Button
                className='ml-1'
                onClick={() => openAddEditEntityModal({})}
              >
                <FaPlus/>
              </Button>)
            }
          />

          <SearchableSelect
            name='carrierAgent'
            label='Carrier Agent'
            control={control}
            options={carrierAgentOptions}
            rightElement={(
              <Button
                className='ml-1'
                disabled={typeof carrierName !== "object" || !carrierName?.value}
                onClick={() => {
                  if (typeof carrierName === "object" && carrierName?.value) {
                    openAddContactToEntityModal({
                      entityId: Number(carrierName.value),
                      onComplete: refreshCarrierAgents
                    })
                  }
                }
                }
              >
                <FaPlus/>
              </Button>
            )}
          />

          <TextBox
            name='policyNumber'
            label='Policy Number'
            control={control}
          />

          <SmartInput
            name='insurancePremium'
            label='Insurance Premium'
            type='currency'
            control={control}
          />

          <FileUploadControl
            name='documentsToUpload'
            label='Documents'
            control={control}
            multiple
          />

          <Radios
            name='isWaived'
            label='Waived?'
            control={control}
            options={YesNoEnum}
            rules={{required: true}}
          />

          <SearchableSelect
            name='associatedLoans'
            label='Associated Loans'
            control={control}
            options={loanOptions}
            isMulti
            isLoading={!globalSearchDataLoadingValues.LOAN}
          />

          <Radios
            name='isPublic'
            label='Public?'
            control={control}
            options={PublicPrivateEnum}
            rules={{required: true}}
          />

          <SearchableSelect
            name='titleEndorsements'
            label='Endorsements'
            control={control}
            options={titleEndorsementOptions}
            isMulti
          />

          <TextBox
            name='exceptions'
            label='Exceptions'
            control={control}
          />

          <TextBox
            name='notes'
            label='Notes'
            control={control}
          />

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button type="submit">
            {modalState?.insurance?.id ? "Update" : "Save"}
          </Button>
        </Modal.Footer>
      </Form>
      <AddContactToEntityModal/>
    </Modal>
  );
};

export default AddEditInsuranceModal;
