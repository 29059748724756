import axios from "axios";
import getMetaApiPath from "@hornet-api/getMetaApiPath";
import {Mobile2FA} from "@hornet-api/twoFactorAuth/user/mobile/getMobileTokenForWeb";

const resendMobile2FA = async () => {
  const url = `${getMetaApiPath()}/2fa/resend-mobile-notification`;
  return axios.put<Mobile2FA>(url, {}).then((res) => res.data)
};

export default resendMobile2FA;
