import React, {useMemo} from 'react';
import style from './style.module.scss';
import {Chat} from '@interfaces/task';
import Badge from './Badge';
import {FaAngleRight} from 'react-icons/fa';
import isAdminState from "@state/globalState/isAdminState";
import {getChatName, getNumChatNotifications} from '../utils';

type Props = {
  chat: Chat,
  onClick?: () => void,
  title?: JSX.Element | string,
}

const ChatItem = ({
  chat,
  onClick = () => { },
  title = getChatName(chat)
}: Props) => {
  const isAdmin = isAdminState.useValue();
  const {content, attachments = []} = chat?.lastMessage || {};

  const numUnread = useMemo(() => {
    return getNumChatNotifications(chat);
  }, [chat, isAdmin]);

  return (
    <div className={style.taskItem} onClick={onClick}>
      <div className={style.left}>
        <div className={style.name}>
          {title}
        </div>
        <div className={style.messageTextBox}>
          <div className={style.messageText}>
            {/* {chatText} */}
            {
              content
              ?? attachments?.map(att => att.fileName).filter(Boolean).join(', ') + (attachments?.length ? ' uploaded' : '')
              ?? <span className={style.empty}>(EMPTY)</span>
            }
          </div>
        </div>
      </div>
      <div className={style.badgeBox}>
        <div className={style.vAlign}>
          <Badge num={numUnread} />
        </div>
      </div>
      <div className={style.sideArrow}>
        <div className={style.vAlign}>
          <FaAngleRight />
        </div>
      </div>

    </div>
  );
}

export default ChatItem;