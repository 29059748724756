import {Card, Row} from "react-bootstrap";
import {currency, formatDate} from "@common/basic";
import React, {useEffect, useState} from "react";
import {PropertyDetailsServer, PropertyRaw} from "@interfaces/Property";
import {PropertyView} from "@admin-ui/pages/UploaderPage/Property/PropertyImageView";
import {FaCircleNotch} from "react-icons/fa";
import {getPropertyById as getRawPropertyById} from "@hornet-api/properties/getPropertiesList";
import {alertApiErrors} from "@common/errors";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import {PropertyAcquisitionTypeMetadata} from "@admin-ui/pages/PropertyPage/contant";
import {PropertyAcquisitionTypeEnum} from "@interfaces/GeneratedEnums";

type Props = {
  details: PropertyDetailsServer | null | undefined,
  propertyId: number
}
export const PropertyDetailsShow = ({details, propertyId}: Props) => {
  const [isLoading, setIsLoading] = useState(false)
  const [rawProperty, setRawProperty] = useState<PropertyRaw>();

  useEffect(() => {
    setIsLoading(true)
    getRawPropertyById(propertyId)
      .then(setRawProperty)
      .catch(alertApiErrors)
      .finally(() => setIsLoading(false));
  }, [propertyId]);

  return <Card>
    <Card.Header>Property Details</Card.Header>
    {details && (
      <Card.Body>
        <Row>
          <dt className="col-sm-3">Currently Leased:</dt>
          <dd className="col-sm-3"
          >{
            details.isLeased === null
              ? "NA"
              : details.isLeased
                ? "YES"
                : "NO"
          }</dd>
        </Row>
        <Row>
          <dt className="col-sm-3">Purchase Price:</dt>
          <dd className="col-sm-3">{currency(details.purchasePrice)}</dd>
          <dt className="col-sm-3">Purchase Date:</dt>
          <dd className="col-sm-3">{formatDate(details.purchaseDate)}</dd>
        </Row>
        <Row>
          <dt className="col-sm-3">Paid Hard Construction Cost:</dt>
          <dd className="col-sm-3">{currency(details.paidHardConstructionCost)}</dd>
          <dt className="col-sm-3">Cost Basis:</dt>
          <dd className="col-sm-3">{currency(details.costBasis)}</dd>
        </Row>
        <Row>
          <dt className="col-sm-3">As Is Valuation:</dt>
          <dd className="col-sm-3">{currency(details.asIsValuation)}</dd>
          <dt className="col-sm-3">After Repair Value:</dt>
          <dd className="col-sm-3">{currency(details.afterRepairValue)}</dd>
        </Row>
        <Row>
          <dt className="col-sm-3">Construction Budget:</dt>
          <dd className="col-sm-3">{currency(details.constructionBudget)}</dd>
          <dt className="col-sm-3">Monthly Market Rent:</dt>
          <dd className="col-sm-3">{currency(details.monthlyMarketRent)}</dd>
        </Row>
        <Row>
          <dt className="col-sm-3">Monthly Other Expenses:</dt>
          <dd className="col-sm-3">{currency(details.monthlyOtherExpenses)}</dd>
          <dt className="col-sm-3">Monthly HOA Dues:</dt>
          <dd className="col-sm-3">{currency(details.monthlyHOADues)}</dd>
        </Row>
        <Row>
          <dt className="col-sm-3">Annual Taxes Owed:</dt>
          <dd className="col-sm-3">{currency(details.annualTaxesOwed)}</dd>
          <dt className="col-sm-3">Yearly Insurance Premium:</dt>
          <dd className="col-sm-3">{currency(details.yearlyInsurancePremium)}</dd>
        </Row>
        <Row>
          <dt className="col-sm-3">Sold Price:</dt>
          <dd className="col-sm-3">{currency(details.soldPrice)}</dd>
          <dt className="col-sm-3">Sold Date:</dt>
          <dd className="col-sm-3">{formatDate(details.soldDate)}</dd>
        </Row>
        <Row>
          <dt className="col-sm-3">Acquisition Type:</dt>
          <dd className="col-sm-3">
            {
              details.acquisitionType && (
                <OverlayTrigger
                  placement="top-end"
                  overlay={(
                    <Tooltip
                      id={`acquisition-type-tooltip-${details.acquisitionType}`}
                    >{PropertyAcquisitionTypeMetadata[details.acquisitionType]}</Tooltip>
                  )}
                >
                  <text>{PropertyAcquisitionTypeEnum[details.acquisitionType]}</text>
                </OverlayTrigger>
              )}
          </dd>
        </Row>
        {rawProperty ?
          isLoading ? <FaCircleNotch className={'spin mt-3'}/> :
            <div className={'r-adminuploader-propertyshowview mt-3'}>
              <PropertyView setProperty={setRawProperty} property={rawProperty} isNavigation={false}/>
            </div>
          : <></>
        }
      </Card.Body>
    )}
  </Card>
}