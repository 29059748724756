import axios from 'axios';
import getAdminApiPath from '../getAdminApiPath';
import {PropertyRaw, PropertyServer} from '@interfaces/Property';
import {PropertyAcquisitionTypeEnum} from "@interfaces/GeneratedEnums";

interface OwnedByData {
  ownedBy?: number | string | null,
  ownerCategory?: string
}

interface AsIsValuationData {
  details: {
    asIsValuation: number | null
  }
}

interface AcquisitionTypeData {
  details: {
    acquisitionType: keyof typeof PropertyAcquisitionTypeEnum | '-'; // '-' is to set it null (handled in the backend)
  }
}

interface AfterRepairValueData {
  details: {
    afterRepairValue: number | null
  }
}

export const updatePropertyInlineValues = async (id: number, data: OwnedByData | AsIsValuationData | AfterRepairValueData | AcquisitionTypeData) => {
  const url = `${getAdminApiPath()}/property/${id}`;
  const response = await axios.patch(url, data);
  return response.data as PropertyRaw;
};

export const updateProperty = async (propertyId: number, data: FormData) => {
  const url = `${getAdminApiPath()}/property/${propertyId}`;
  const response = await axios.put(url, data);
  return response.data as PropertyServer;
};


