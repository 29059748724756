import {AccreditedInvestorStatusOfEntityEnum, EntityCategoryEnum, EntityTypeEnum} from "@interfaces/GeneratedEnums";
import {statesTitleCase} from "@common/states";
import {YesNoEnum} from "@interfaces/Enums";
import {SelectOptionProps} from "@components/forms/react-hook-form-bootstrap/interfaces";

type AddEditEntityFormData = {
  name: string;
  email: string | null;
  entityType?: keyof typeof EntityTypeEnum;
  ein: string | null;
  stateOfFormation?: keyof typeof statesTitleCase; // required
  isQualifiedPlan: keyof typeof YesNoEnum;
  accreditedInvestorStatusOfEntity?: keyof typeof AccreditedInvestorStatusOfEntityEnum | null;
  entityCategory?: keyof typeof EntityCategoryEnum;
  isDisregardedEntity: keyof typeof YesNoEnum;
  companyHeaderOverride?: string | null;
  qbBorrowerName?: string | null;
  qbCustomerName?: string | null;
  isVendor: boolean;
  pointOfContact: SelectOptionProps | null;
  taxableEntityName?: string;
}

export const getBooleanString = (value?: boolean): undefined | keyof typeof YesNoEnum => {
  if (value !== undefined) {
    return value ? 'true' : 'false'
  }
}

export const defaultFormData: AddEditEntityFormData = {
  name: '',
  email: '',
  ein: '',
  isQualifiedPlan: 'false',
  isDisregardedEntity: 'false',
  isVendor: false,
  pointOfContact: null,
}

export type {AddEditEntityFormData}