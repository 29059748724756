import React, {useEffect, useMemo, useState} from 'react';
import {Button, Form, Modal} from "react-bootstrap";
import {AccreditedInvestorStatusOfEntityEnum, EntityCategoryEnum, EntityTypeEnum} from "@interfaces/GeneratedEnums";
import {statesTitleCase} from "@common/states";
import {alertApiErrors} from "@common/errors";
import hasCompanyDrawRequests from "@hornet-api/company/hasCompanyDrawRequests";
import {userListState} from "@components/NotificationSidebar/globalState";
import {createGlobalState} from "react-global-hooks";
import {SubmitErrorHandler, SubmitHandler, useForm} from "react-hook-form";
import {
  AddEditEntityFormData,
  defaultFormData,
  getBooleanString
} from "@admin-ui/pages/EntityPage/components/AddEditEntityModal/utils";
import updateCompany from "@hornet-api/company/updateCompany";
import createdCompany from "@hornet-api/company/createdCompany";
import {AddEditCompanyServer} from "@interfaces/Company";
import {Checkbox, SearchableSelect, Select, SmartInput, TextBox} from "@components/forms/react-hook-form-bootstrap";
import {YesNoEnum} from "@interfaces/Enums";
import {loadingRelease, loadingTrigger} from "@components/LoadingOverlay";
import {getEntityForEdit} from "@hornet-api/entities/getEntityForEdit";
import decryptObject from "@common/decryptObject";
import {isValidTaxIdValue} from "@common/utils/validators/taxValidator";
import {BsBoxArrowUpRight} from "react-icons/bs";
import {addAlert} from "@components/Alert";
import {isEmailValid} from "@common/utils/validators";
import {refreshCompanyList} from "@components/NotificationSidebar/utils";
import {onInvalidHandler} from "@components/forms/react-hook-form-bootstrap/utils";

const formatEin = (ein: string | null) => {
  if (!ein) return '';
  // format the ein string with an - in the position of the 3rd character i.e. 12-3456789 the ein must be 9 characters long and contain no letters
  if (ein.length === 9 && !isNaN(Number(ein))) {
    return ein.slice(0, 2) + '-' + ein.slice(2);
  } else {
    return ein;
  }
}

interface IAddEditEntityModalState {
  id?: number;
  isEditingForLenders?: boolean;
  onComplete?: (updatedId: number) => Promise<void> | void;
  onClose?: () => Promise<void> | void
}

export const addEditEntityModalState = createGlobalState<IAddEditEntityModalState | null>(null);

export const openAddEditEntityModal = (newState: IAddEditEntityModalState) => {
  addEditEntityModalState.set(newState);
}

const AddEditEntityModal = () => {
  const [modalState, setModalState] = addEditEntityModalState.use();

  const allContacts = userListState.useValue();
  const [hasDrawRequests, setHasDrawRequests] = useState<boolean>(false);
  const entityId = modalState?.id
  const isEditingForLenders = modalState?.isEditingForLenders || false;

  const [isEinRequired, setIsEinRequired] = useState<boolean>(false);
  const {
    handleSubmit,
    control,
    reset,
    watch,
  } = useForm<AddEditEntityFormData>();

  const isDisregardedEntity = watch('isDisregardedEntity') === 'true';
  const entityType = watch('entityType');
  const isVendor = watch('isVendor');

  const allContactsOptions = useMemo(() => {
    return [{value: '', label: '-- Please Select --'}].concat(
      (allContacts || []).map(({id, name}) => ({
        value: `${id}`, label: `${name}`
      }))
    )
  }, [allContacts]);

  useEffect(() => {
    if (!entityId || !modalState) {
      reset(defaultFormData)
      return
    }
    const t = loadingTrigger();
    Promise.all([
      getEntityForEdit(entityId as number)
        .then(decryptObject)
        .then((entity) => {
          reset({
            ...entity,
            isQualifiedPlan: getBooleanString(entity.isQualifiedPlan),
            isDisregardedEntity: getBooleanString(entity.isDisregardedEntity),
            isVendor: entity.isVendor,
            ein: formatEin(entity.ein),
            pointOfContact: allContactsOptions.find(({value}) => value === `${entity.pointOfContact || ''}`)
          });
          setIsEinRequired(entity.ein !== null)
        }),
      hasCompanyDrawRequests(entityId)
        .then(setHasDrawRequests)
    ])
      .catch(alertApiErrors)
      .finally(() => loadingRelease(t));
  }, [entityId, modalState])

  const handleCloseOnCompletion = () => {
    setModalState(null);
  }

  const handleClose = () => {
    setModalState(null);
    modalState?.onClose?.();
  }

  const onSubmit: SubmitHandler<AddEditEntityFormData> = (data) => {
    if (!data.entityType || !data.stateOfFormation || !data.accreditedInvestorStatusOfEntity) {
      return;
    }
    const t = loadingTrigger();
    const requestBody: AddEditCompanyServer = {
      ...data,
      entityType: data.entityType,
      stateOfFormation: data.stateOfFormation,
      accreditedInvestorStatusOfEntity: data.accreditedInvestorStatusOfEntity,
      isQualifiedPlan: data.isQualifiedPlan === 'true',
      isDisregardedEntity: data.isDisregardedEntity === 'true',
      ein: formatEin(data.ein).replace('-', ''),
      pointOfContact: (data.pointOfContact?.value as number) || null
    };

    (entityId ? updateCompany(entityId, requestBody) : createdCompany(requestBody))
      .then((entity) => modalState?.onComplete?.(entity.id))
      .then(handleCloseOnCompletion)
      .then(() => {
        refreshCompanyList()
        addAlert({
          type: 'success',
          content: `Entity has been successfully ${entityId ? 'updated.' : 'created.'}`
        })
      })
      .catch(alertApiErrors)
      .finally(() => loadingRelease(t));
  }
  const onInvalid: SubmitErrorHandler<AddEditEntityFormData> = (errs) => {
    onInvalidHandler(errs)
  }

  return (
    <Modal
      show={!!modalState}
      onHide={handleClose}
      id={'EdiCompanyModal'}
      size={'lg'}
    >
      <Modal.Header closeButton>
        <Modal.Title>{entityId ? 'Edit' : 'Add'} Entity</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate onSubmit={handleSubmit(onSubmit,onInvalid)} className={'position-relative form-floating'}>
          <TextBox
            label="Entity Name"
            name="name"
            control={control}
            showOnly={isEditingForLenders}
            rules={{
              required: true
            }}
          />
          <TextBox
            label={"SAGE Investor Name"}
            name={"qbCustomerName"}
            control={control}
          />
          <TextBox
            label={"SAGE Borrower Name"}
            name={"qbBorrowerName"}
            control={control}
          />
          <Select
            label="Entity Type"
            name="entityType"
            control={control}
            options={EntityTypeEnum}
            emptyOption={'-- Please Select --'}
            rules={{
              required: true
            }}
          />
          <Select
            label="Entity Category"
            name="entityCategory"
            control={control}
            options={EntityCategoryEnum}
            emptyOption={'-- Please Select --'}
          />
          <SmartInput
            name={'ein'}
            label={'Tax ID (EIN)'}
            type={'ein'}
            control={control}
            rules={{
              required: isEinRequired,
              validate: {
                validEin: (ein: string) => {
                  if (ein && !isValidTaxIdValue(ein)) {
                    return 'EIN must be 9 digits only numbers allowed'
                  }
                }
              }
            }}
          />
          <Select
            label="State of Formation"
            name="stateOfFormation"
            control={control}
            options={statesTitleCase}
            emptyOption={'-- Please Select --'}
            rules={{
              required: true
            }}
          />
          <Select
            label="For K-1 reporting, is this entity a retirement plan (IRA/SEP/Keogh/etc.)?"
            name="isQualifiedPlan"
            control={control}
            options={YesNoEnum}
            emptyOption={'-- Please Select --'}
            rules={{
              required: true
            }}
          />
          <Select
            label="Is A Disregarded Entity?"
            name="isDisregardedEntity"
            control={control}
            options={YesNoEnum}
            emptyOption={'-- Please Select --'}
            rules={{
              required: true
            }}
          />
          {isDisregardedEntity && (
            <TextBox
              label="Taxable Entity Name"
              name="taxableEntityName"
              control={control}
            />
          )}
          <Select
            label="Accredited Investor Status"
            name="accreditedInvestorStatusOfEntity"
            control={control}
            options={AccreditedInvestorStatusOfEntityEnum}
            emptyOption={'-- Please Select --'}
            rules={{
              required: true
            }}
          />
          <SearchableSelect
            label={'Point of Contact'}
            name={'pointOfContact'}
            control={control}
            options={allContactsOptions}
            rules={{
              required: false
            }}
          />
          <TextBox
            label="Company Header Override"
            name="companyHeaderOverride"
            control={control}
          />
          <Checkbox
            label="Is Vendor"
            name="isVendor"
            control={control}
            rules={{
              required: hasDrawRequests && 'There are existing Draw Requests for this Vendor. Cannot remove Vendor.'
            }}
          />
          <TextBox
            label="Email"
            name="email"
            control={control}
            type={'email'}
            rules={{
              required: isVendor || (isEditingForLenders && entityType && !(["LLC", "CORPORATION"] as Array<keyof typeof EntityTypeEnum>).includes(entityType)),
              validate: {
                validEmail: (cc?: string) => {
                  return cc && !isEmailValid(cc) ?
                    // Has selected invalid emails? send error message
                    `Invalid email`
                    // No invalid email, return true for valid values
                    : true
                }
              }
            }}
          />


          {isEditingForLenders && (
            <a
              href={`/entity/show/${entityId}`}
              target={'_blank'}
            >
              Edit Phone/Address information <BsBoxArrowUpRight/>
            </a>
          )}
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button
              variant="primary"
              type="submit"
            >
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AddEditEntityModal;
