import React, {useEffect, useState} from "react";
import {FaBell, FaComment} from "react-icons/fa";
import {Button, Col, Row, Tooltip} from "react-bootstrap";
import style from "./MobileTwoFactorInformation.module.scss";
import {AvailableTwoFactorOption, availableTwoFactorOptions,} from "@hornet-api/twoFactorAuth/meta/twoFactorOption";
import {alertApiErrors} from "@common/errors";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import {TwoFaTypeEnum} from "@interfaces/GeneratedEnums";

type Props = {
  onSelect: (type: keyof typeof TwoFaTypeEnum) => void;
};

const AvailableTwoFactorOptionsView = ({onSelect}: Props) => {
  const [options, setOptions] = useState<AvailableTwoFactorOption | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    availableTwoFactorOptions()
      .then(setOptions)
      .catch(alertApiErrors)
      .finally(() => setIsLoading(false));
  }, []);

  const renderTwoFactorOptionRow = (
    label: string,
    tooltip: string,
    icon: React.ReactNode,
    action: keyof typeof TwoFaTypeEnum,
    buttonVariant: string
  ) => (
    <Row className={`${style.rowCustom} m-2`}>
      <Col xs={10}>{label}</Col>
      <Col xs={2}>
        <OverlayTrigger
          placement="right"
          overlay={<Tooltip id={`tooltip-${action}`}>{tooltip}</Tooltip>}
        >
          <Button
            className="btn btn-sm"
            variant={buttonVariant}
            onClick={() => onSelect(action)}
          >
            {icon}
          </Button>
        </OverlayTrigger>
      </Col>
    </Row>
  );

  if (isLoading) {
    return null; // You can replace this with a loading spinner if needed
  }

  return (
    <>
      {renderTwoFactorOptionRow(
        "Resend Two Factor Notification",
        "Resend",
        <FaBell size={20}/>,
        "APP_PUSH_NOTIFICATION",
        "success"
      )}
      {renderTwoFactorOptionRow(
        `Get OTP via SMS on ${options?.twoFaPhone || "your phone"}`,
        "Send SMS",
        <FaComment size={20}/>,
        "SMS",
        "info"
      )}
    </>
  );
};

export default AvailableTwoFactorOptionsView;
