import React, {useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import devConsole from "@common/devConsole";

const NoAuthRedirect = () => {
  // http://localhost:3000/userContact/investor?prrrr=eyJic3RfL3VzZXJDb2
  const history = useHistory();
  useEffect(() => {
    // save the current location so we can redirect back to it
    // after login
    const { pathname, search } = window.location;
    // don't store it if root
    if (['/', '', '/login'].includes(pathname) || pathname.startsWith('/stomp')) {
      localStorage.removeItem('NoAuthRedirect');
    } else {
      const returnTo = pathname + search;
      devConsole.log('Saving NoAuthRedirect', returnTo);
      localStorage.setItem('NoAuthRedirect', returnTo);
    }
    // redirect to login if not authenticated
    history.push('/login');
  }, []);
  return <></>;
};

export default NoAuthRedirect;
