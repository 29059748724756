import axios from 'axios';
import isAdminState from "@state/globalState/isAdminState";
import getUserApiPath from "@hornet-api/getUserApiPath";
import getAdminApiPath from "@hornet-api/getAdminApiPath";

export type SendMessageOption = {
  chat: number;
  id?: number; // message id sent when a chat message is being edited
  content: string;
}

export const sendMessage = async (messageBody: SendMessageOption) => {
  const baseUrl = isAdminState.get() ? getAdminApiPath() : getUserApiPath();
  const url = `${baseUrl}/chat`;
  return (messageBody.id ? axios.put : axios.post)(url, messageBody)
}

export const uploadChatAttachments = async (formData: FormData) => {
  const baseUrl = getAdminApiPath();
  const url = `${baseUrl}/chat`;
  return axios.post(url, formData)
}
