import {createGlobalState} from "react-global-hooks";
import {useForm} from "react-hook-form";
import {Button, Form, Modal} from "react-bootstrap";
import {Select, TextBox} from "@components/forms/react-hook-form-bootstrap";
import {isEmailValid} from "@common/utils/validators";
import {YesNoEnum} from "@interfaces/Enums";
import {FaCircleNotch} from "react-icons/fa";
import React from "react";
import createContactForEntity from "@hornet-api/company/contactPosition/createNewMember";
import {alertApiErrors} from "@common/errors";
import {loadingRelease, loadingTrigger} from "@components/LoadingOverlay";
import SmartInput from "@components/forms/react-hook-form-bootstrap/SmartInput";
import addPhoneNumber from "@hornet-api/contact/phoneNumber/addPhoneNumber";

interface IAddContactToEntityForm {
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  canSign: 'true' | 'false';
  position: string;
}

interface IAddContactToEntityModalState {
  entityId: number;
  onComplete?: () => Promise<void> | void;
  onClose?: () => Promise<void> | void;
}

const defaultForm: IAddContactToEntityForm = {
  email: '',
  firstName: '',
  middleName: '',
  lastName: '',
  phoneNumber: '',
  canSign: 'true',
  position: '',
};

const addContactToEntityModalState = createGlobalState(null as null | IAddContactToEntityModalState);


export const openAddContactToEntityModal = (data: IAddContactToEntityModalState) => {
  addContactToEntityModalState.set({...data});
};

const AddContactToEntityModal = () => {

  const [modalState, setModalState] = addContactToEntityModalState.use();

  const {control, handleSubmit, reset, formState: {isSubmitting}} = useForm<IAddContactToEntityForm>({defaultValues: defaultForm});

  const handleClose = () => {
    setModalState(null);
    reset(defaultForm);
    modalState?.onClose?.();
  };

  const onSubmit = (data: IAddContactToEntityForm) => {
    if (!modalState?.entityId) return;

    const {phoneNumber, ...contactForm} = data

    const t = loadingTrigger();
    createContactForEntity(modalState.entityId, {...contactForm, canSign: data.canSign === 'true'})
      .then((res) => phoneNumber ? addPhoneNumber(res.id, {phoneNumber, isPrimary: false}) : null)
      .then(modalState.onComplete)
      .then(handleClose)
      .catch(alertApiErrors)
      .finally(() => loadingRelease(t));
  }

  return (
    <Modal
      show={!!modalState}
      onHide={handleClose}
      id={'addContactModal'}
    >
      <Modal.Header closeButton>
        <Modal.Title>Add a Contact to your Entity</Modal.Title>
      </Modal.Header>
      <Form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <TextBox
            name={`firstName`}
            label={'First Name'}
            control={control}
            rules={{required: true}}
          />
          <TextBox
            name={`lastName`}
            label={'Last Name'}
            control={control}
            rules={{required: true}}
          />
          <TextBox
            name={`email`}
            label={'Email Address'}
            control={control}
            rules={{
              required: true,
              validate: {
                validEmail: (email?: string) => email && isEmailValid(email) ? true : 'The Email Address field has an invalid value.'
              }
            }}
          />
          <SmartInput
            type={'phone'}
            name={'phoneNumber'}
            label={'Phone Number'}
            control={control}
          />
          <TextBox
            name={`position`}
            label={'Position'}
            control={control}
            rules={{required: true}}
          />

          <Select
            options={YesNoEnum}
            name='canSign'
            label='Can Sign'
            control={control}
            rules={{required: true}}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" disabled={isSubmitting} type={'submit'}>
            {isSubmitting ? <FaCircleNotch className={'spin'}/> : 'Add Contact'}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default AddContactToEntityModal;