import React, {useEffect, useState} from 'react';
import {useDropzone} from "react-dropzone";

export interface DropZonePropObj {
  onDropFiles: (files: File[]) => void;
  message: string
}

export enum DragCounterEnum {
  ZERO,
  ONE,
}

const FileDropZone = (
  {
    onDropFiles,
    message,
  }: DropZonePropObj
) => {

  const [isDragging, setIsDragging] = useState(false);

  let dragCounter = 0;

  useEffect(() => {
    const handleDragEnter = (e: DragEvent) => {
      e.preventDefault();
      e.stopPropagation();
      dragCounter += 1; // Increment counter on drag enter
      if (dragCounter === DragCounterEnum.ONE) {
        setIsDragging(true); // Activate overlay when entering for the first time
      }
    };

    const handleDragLeave = (e: DragEvent) => {
      e.preventDefault();
      e.stopPropagation();
      dragCounter -= 1; // Decrement counter on drag leave
      if (dragCounter === DragCounterEnum.ZERO) {
        setIsDragging(false); // Deactivate overlay when completely left
      }
    };

    const handleDragOver = (e: DragEvent) => {
      e.preventDefault();
      e.stopPropagation();
    };

    const handleDrop = (e: DragEvent) => {
      e.preventDefault();
      e.stopPropagation();
      dragCounter = 0; // Reset counter after drop
      setIsDragging(false); // Deactivate after drop
    };

    // Add global event listeners
    window.addEventListener('dragenter', handleDragEnter);
    window.addEventListener('dragleave', handleDragLeave);
    window.addEventListener('dragover', handleDragOver);
    window.addEventListener('drop', handleDrop);

    return () => {
      // Clean up global event listeners
      window.removeEventListener('dragenter', handleDragEnter);
      window.removeEventListener('dragleave', handleDragLeave);
      window.removeEventListener('dragover', handleDragOver);
      window.removeEventListener('drop', handleDrop);
    };
  }, []);


  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    onDrop: async (acceptedFiles) => {
      onDropFiles(acceptedFiles);
      setIsDragging(false);
    },
  });

  return (
    <>
      {(isDragActive || isDragging) && (
        <div
          {...getRootProps()}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(92,114,131,0.5)', // Bootstrap primary color with opacity
            zIndex: 1000,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <input {...getInputProps()} />
          <p className={'mt-5 text-white'}><strong>{message}</strong></p>
        </div>
      )}
    </>
  )
}

export default FileDropZone;
