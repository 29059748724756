import React, {useEffect, useMemo, useRef, useState} from 'react';
import {createGlobalState} from 'react-global-hooks';
import {FaCircleNotch} from 'react-icons/fa';
import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import {SubmitErrorHandler, SubmitHandler, useForm} from "react-hook-form";
import {
  Date,
  FileUploadControl,
  Radios,
  SearchableSelect,
  Select,
  TextBox
} from "@components/forms/react-hook-form-bootstrap";
import {enumKeys, objCopy, sortByKeyIgnoreCase} from "@common/basic";
import {alertApiErrors} from "@common/errors";
import {useHistory} from "react-router-dom";
import {onInvalidHandler} from "@components/forms/react-hook-form-bootstrap/utils";
import {PropertyAcquisitionTypeEnum, PropertyConditionEnum, PropertyTypeEnum} from "@interfaces/GeneratedEnums";
import {PropertyFromData, PropertyServer} from "@interfaces/Property";
import {IOptionWithEntityType} from "@admin-ui/pages/LoanPage/AddEditLoan/constant";
import {companyListState, userListState} from "@components/NotificationSidebar/globalState";
import {County} from "@interfaces/State";
import {getCountyByAbbreviation} from "@hornet-api/states/getCountyList";
import {YesNoTextEnum} from "@interfaces/Enums";
import {SmartAddress} from "@components/forms/react-hook-form-bootstrap/SmartAddress";
import {
  convertPropertyServerToFormData,
  convertPropertyToFormData,
  getAcquisitionTypeOption,
  PropertyAcquisitionTypeMetadata
} from "@admin-ui/pages/PropertyPage/contant";
import {createProperty} from "@hornet-api/properties/createProperty";
import {updateProperty} from "@hornet-api/properties/updateProperty";
import {getPropertyById} from "@hornet-api/properties/getProperty";
import {loadingRelease, loadingTrigger} from "@components/LoadingOverlay";
import UserLoanCollateralPropertyMap from "@legacy/views/UserLoanView/UserLoanCollateralPropertyMap";
import {refreshPropertyList} from "@components/NotificationSidebar/utils";

interface AddEditPropertyModalStateState {
  id?: number;
  onComplete: (updatedPropertyServer: PropertyServer) => Promise<void> | void;
}

const defaultPropertyFrom: PropertyFromData = {
  propertyType: null,
  numberOfProperties: '',
  owner: null,
  legalAddressDocument: null,
  legalAddress: null,
  lotSize: null,
  initialProperty: '',
  finalProperty: '',
  accessInfo: '',
  otherFeature: '',
  asIsCondition: null,
  mailingAddress: {
    address1: '',
    address2: null,
    address3: null,
    state: null,
    city: '',
    counties: [],
    zip: '',
    longitude: null,
    latitude: null
  },
  details: {
    purchasePrice: '',
    purchaseDate: null,
    paidHardConstructionCost: '',
    asIsValuation: '',
    constructionBudget: '',
    afterRepairValue: '',
    isLeased: null,
    monthlyMarketRent: '',
    monthlyHOADues: '',
    annualTaxesOwed: '',
    yearlyInsurancePremium: '',
    soldPrice: '',
    soldDate: null,
    monthlyOtherExpenses: '',
    acquisitionType: getAcquisitionTypeOption("PURCHASE"),
  }
};
export type MapLocation = {
  longitude: number;
  latitude: number;
}
const addEditPropertyModalState = createGlobalState(null as null | AddEditPropertyModalStateState);

export const openAddEditPropertyModal = (modalState: AddEditPropertyModalStateState) => {
  addEditPropertyModalState.set({
    ...modalState
  });
};

const AddEditPropertyModal = () => {
  const history = useHistory();
  const [modalState, setModalState] = addEditPropertyModalState.use();
  const editingId = modalState?.id
  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    trigger
  } = useForm<PropertyFromData>({defaultValues: defaultPropertyFrom});
  const [isSaving, setIsSaving] = useState(false);
  const firstInputRef = useRef<HTMLInputElement>(null);
  const [counties, setCounties] = useState([] as County[]);
  const contacts = userListState.useValue();
  const companies = companyListState.useValue();

  const watchPropertyAddressState = watch('mailingAddress.state');

  const watchPropertyAddress = watch('mailingAddress');

  useEffect(() => {
    // load from server if editing
    if (editingId) {
      const t = loadingTrigger();
      getPropertyById(editingId)
        .then((server) => {
          reset(convertPropertyServerToFormData(server));
        })
        .catch(alertApiErrors)
        .finally(() => loadingRelease(t));
    }
  }, [editingId])

  useEffect(() => {
    if (watchPropertyAddressState) {
      getCountyByAbbreviation(`${watchPropertyAddressState}`).then((data) => {
        setCounties(data);
      }).catch(alertApiErrors);
    } else {
      setCounties([]);
    }
  }, [watchPropertyAddressState])

  const countyOptions = useMemo(() => {
    return counties.map(({id, name}) => ({
      value: id,
      label: name
    }))
  }, [counties])

  const acquisitionTypeOptions = useMemo(() => {
    return enumKeys(PropertyAcquisitionTypeEnum)
      .map((key) => ({
        value: key,
        label: PropertyAcquisitionTypeEnum[key],
        tooltip: PropertyAcquisitionTypeMetadata[key]
      }));
  }, []);

  const combinedEntities = useMemo(() => {
    return sortByKeyIgnoreCase(
      (contacts || [])
        .map(({id, name}) => ({
          value: id,
          label: name,
          entityType: 'CONTACT',
        } as IOptionWithEntityType)),
      'label'
    ).concat(
      sortByKeyIgnoreCase(
        (companies || []).map(({id, name}) => ({
          value: id,
          label: `${name}`,
          entityType: 'ENTITY'
        } as IOptionWithEntityType)),
        'label'
      )
    );
  }, [contacts, companies]);

  const updateMapLocation = ({longitude, latitude}: MapLocation) => {
    setValue("mailingAddress.latitude", latitude);
    setValue("mailingAddress.longitude", longitude);
  }

  const handleClose = () => {
    if (editingId) {
      history.push(`/property/show/${editingId}`);
    } else {
      history.push('/property');
    }
    setModalState(null);
    reset(objCopy(defaultPropertyFrom));
  };

  const onSubmit: SubmitHandler<PropertyFromData> = async (data) => {
    if (!modalState || isSaving) return;
    setIsSaving(true);
    const requestData = convertPropertyToFormData({
      ...data
    });
    (
      editingId ?
        updateProperty(editingId, requestData)
        : createProperty(requestData)
    )
      .then(modalState.onComplete)
      .then(handleClose)
      .then(refreshPropertyList)
      .catch(alertApiErrors)
      .finally(() => setIsSaving(false));

  };

  const onInvalid: SubmitErrorHandler<PropertyFromData> = async (errs) => {
    onInvalidHandler(errs)
  };


  return (
    <Modal
      show={!!modalState}
      onHide={handleClose}
      id={'addPropertyModal'}
      size={'xl'}
      onEntered={() => {
        setTimeout(() => {
          if (firstInputRef.current) firstInputRef.current.focus();
        }, 300);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>{editingId ? 'Edit' : 'Add'} Property</Modal.Title>
      </Modal.Header>
      <Form noValidate onSubmit={handleSubmit(onSubmit, onInvalid)}>
        <Modal.Body className={'position-relative'}>
          <Row>
            <Col>
              <Select
                name={'propertyType'}
                label={'Property Type'}
                control={control}
                options={PropertyTypeEnum}
              />
            </Col>
            <Col xs={6}>
              <SearchableSelect
                name={'owner'}
                label={'Owner'}
                control={control}
                options={combinedEntities}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <TextBox
                name={'numberOfProperties'}
                label={'Number of Units'}
                //Solely reflects as "Number of Units" on front-end so as differentiate this data from "Number Of Properties" label on the /loan/show/# > Collateral Table > "Number of Properties" column. They are not the same data.
                control={control}
                type={'number'}
                rules={{
                  required: true
                }}
                step={1}
                placeholder={'0'}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={4}>
              <TextBox
                name={'lotSize'}
                label={'Lot size in acres'}
                control={control}
                type={'number'}
              />
            </Col>
            <Col xs={4}>
              <Select
                name={'asIsCondition'}
                label={'As-is Condition'}
                control={control}
                options={PropertyConditionEnum}
              />
            </Col>
            <Col xs={4}>
              <TextBox
                name={'accessInfo'}
                label={'Access Info'}
                control={control}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={4}>
              <TextBox
                name={'otherFeature'}
                label={'Other features'}
                control={control}
              />
            </Col>
            <Col xs={4}>
              <TextBox
                name={'finalProperty'}
                label={'Final'}
                control={control}
              />
            </Col>
            <Col xs={4}>
              <TextBox
                name={'initialProperty'}
                label={'Initial'}
                control={control}
              />
            </Col>
          </Row>

          <SmartAddress
            showAddressType={false}
            rules={{required: true}}
            name={'mailingAddress'}
            label={'Property Address'}
            control={control}
            googleSearch={true}
            setValue={setValue}
            trigger={trigger}
          />
          <Row>
            <Col>
              <SearchableSelect
                name={'mailingAddress.counties'}
                label={'Counties'}
                control={control}
                rules={{
                  required: true,
                }}
                isMulti={true}
                options={countyOptions}
              />
            </Col>
            <Col>
              <TextBox
                name={'mailingAddress.longitude'}
                label={'Longitude'}
                control={control}
                type={'number'}
                step={0.000000}
                placeholder={'0.000000'}
              />
            </Col>
            <Col>
              <TextBox
                name={'mailingAddress.latitude'}
                label={'Latitude'}
                control={control}
                type={'number'}
                step={0.000000}
                placeholder={'0.000000'}
              />
            </Col>
          </Row>
          {watchPropertyAddress && <UserLoanCollateralPropertyMap
              property={{mailingAddress: watchPropertyAddress}}
              containerStyle={{height: '250px', width: '100%'}}
              updateMapLocation={updateMapLocation}
          />}
          <FileUploadControl
            name={'legalAddressDocument'}
            label={'Legal Address Document'}
            control={control}
          />
          <TextBox
            name={'legalAddress'}
            label={'Legal Address'}
            control={control}
            as={'textarea'}
          />

          <Row>
            <Col>
              <TextBox
                name={'details.purchasePrice'}
                label={'Purchase Price'}
                control={control}
                type={'number'}
                placeholder={'0.00'}
              />
            </Col>
            <Col>
              <Date
                name={'details.purchaseDate'}
                label={'Purchase Date'}
                control={control}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextBox
                name={'details.paidHardConstructionCost'}
                label={'Paid Hard Construction Cost'}
                control={control}
                type={'number'}
                placeholder={'0.00'}
              />
            </Col>
            <Col>
              <TextBox
                name={'details.asIsValuation'}
                label={'As Is Valuation'}
                control={control}
                type={'number'}
                placeholder={'0.00'}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextBox
                name={'details.constructionBudget'}
                label={'Construction Budget'}
                control={control}
                type={'number'}
                placeholder={'0.00'}
              />
            </Col>
            <Col>
              <TextBox
                name={'details.afterRepairValue'}
                label={'After Repair Value'}
                control={control}
                type={'number'}
                placeholder={'0.00'}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Radios
                name={'details.isLeased'}
                label={'Is Leased?'}
                control={control}
                options={YesNoTextEnum}
              />
            </Col>
            <Col>

              <TextBox
                name={'details.monthlyMarketRent'}
                label={'Monthly Market Rent'}
                control={control}
                type={'number'}
                placeholder={'0.00'}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextBox
                name={'details.monthlyOtherExpenses'}
                label={'Monthly Other Expenses'}
                control={control}
                type={'number'}
                placeholder={'0.00'}
              />
            </Col>
            <Col>
              <TextBox
                name={'details.monthlyHOADues'}
                label={'Monthly HOA Dues'}
                control={control}
                type={'number'}
                placeholder={'0.00'}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextBox
                name={'details.annualTaxesOwed'}
                label={'Annual Taxes Owed'}
                control={control}
                type={'number'}
                placeholder={'0.00'}
              />
            </Col>
            <Col>
              <TextBox
                name={'details.yearlyInsurancePremium'}
                label={'Yearly Insurance Premium'}
                control={control}
                type={'number'}
                placeholder={'0.00'}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextBox
                name={'details.soldPrice'}
                label={'Sold Price'}
                control={control}
                type={'number'}
                placeholder={'0.00'}
              />
            </Col>
            <Col>
              <Date
                name={'details.soldDate'}
                label={'Sold Date'}
                control={control}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={6}>
              <SearchableSelect
                name={'details.acquisitionType'}
                label={'Acquisition Type'}
                control={control}
                options={acquisitionTypeOptions}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            disabled={isSaving}
            type="submit"
          >
            {
              isSaving ?
                <FaCircleNotch className={'spin'}/>
                : 'Save'
            }
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default AddEditPropertyModal;
