import axios from "axios";
import isAdminState from "@state/globalState/isAdminState";
import getAdminApiPath from "@hornet-api/getAdminApiPath";
import getUserApiPath from "@hornet-api/getUserApiPath";

const notifyUserTyping = async (id: number) => {
  const baseUrl = isAdminState.get() ? getAdminApiPath() : getUserApiPath();
  const url = `${baseUrl}/chat/${id}/notifyTyping`;
  await axios.post(url, {})
}
export default notifyUserTyping