import React from "react";
import {FaLock} from "react-icons/fa";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import style from "./MobileTwoFactorInformation.module.scss";

interface Props {
  textCode: string | null
}

const MobileTwoFactorInformation = ({textCode}: Props) => {

  return (
    <Row className={style.rowCustom}>
      <Col xs={2}><FaLock size={50}/></Col>
      {
        textCode ?
          <><Col xs={10}>
            Open the <i>Hornet Authenticator Application</i> in your mobile and enter the number shown to Sign In.
          </Col>
            <div className={style.centeredTextContainer}>
              <p className={style.centeredText}>{textCode}</p>
            </div>
          </>
          : <Col xs={10}>
            Open the <i>Hornet Authenticator Application</i> on your mobile device and approve your Sign In Request
          </Col>
      }
    </Row>
  )
}

export default MobileTwoFactorInformation;