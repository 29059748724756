import React, {useMemo} from "react";
import {IoAppsOutline} from "react-icons/io5";
import {FaCircleDollarToSlot, FaSackDollar} from "react-icons/fa6";
import {TbDatabaseDollar, TbMailDollar} from "react-icons/tb";
import isFeatureEnabledSelector from "@state/globalState/isFeatureEnabledSelector";
import {createdDrawRequestCountState, submittedBudgetCountState} from "@components/NotificationSidebar/globalState";
import {FaAddressBook, FaHandshake} from "react-icons/fa";
import {GiStamper, GiTemporaryShield} from "react-icons/gi";
import {GoRocket} from "react-icons/go";
import {LiaFileInvoiceDollarSolid} from "react-icons/lia";

export type SubLink = {
	icon?: React.ReactNode,
	name: string,
	linkTo: string,
	onClick?: () => void
}

export type SideBarLinkObject = {
	icon: React.ReactNode,
	name: string,
	count?: number,
	linkTo?: string,
	sideLink?: SubLink,
	subLinks?: SideBarLinkObject[],
	disabled?: boolean,
	restrictedToRoles?: string[],
	frameBreak?: boolean,
	onClick?: () => void
}

const getSideBarLinkObject = (): SideBarLinkObject[] => {
	const isBudgetFeatureEnabled = isFeatureEnabledSelector('budget');
	const isNotaryFeatureEnabled = isFeatureEnabledSelector('notary');
	const submittedBudgetCount = submittedBudgetCountState.useValue();
	const createdDrawRequestCount = createdDrawRequestCountState.useValue();

	return useMemo(() => {
		return [
			{
				icon: 'tachometer',
				name: 'Dashboard',
				linkTo: '/'
			},
			{
				icon: 'building',
				name: 'Entities',
				linkTo: '/entity',
				sideLink: {
					name: 'Add',
					icon: 'plus',
					linkTo: '/entity/create'
				}
			},
			{
				icon: 'users',
				name: 'Contacts',
				linkTo: '/contact',
				sideLink: {
					name: 'Add',
					icon: 'plus',
					linkTo: '/contact/create'
				},
			},
			{
				icon: 'home',
				name: 'Properties',
				linkTo: '/property',
				sideLink: {
					name: 'Add',
					icon: 'plus',
					linkTo: '/property/create'
				}
			},
			{
				icon: 'usd',
				name: 'Loans',
				linkTo: '/loan',
				sideLink: {
					name: 'Add',
					icon: 'plus',
					linkTo: '/loan/create'
				}
			},
			{
				icon: <FaHandshake/>,
				name: 'Insurance',
				linkTo: '/insurance',
				sideLink: {
					name: 'Add',
					icon: 'plus',
					linkTo: '/insurance/create'
				}
			},
			...(
				isBudgetFeatureEnabled ?
					[
						{
							icon: <FaSackDollar/>,
							name: 'Budgets',
							count: submittedBudgetCount + createdDrawRequestCount,
							subLinks: [
								{
									name: 'Budget List',
									linkTo: '/budgets',
									icon: <FaCircleDollarToSlot/>,
									count: submittedBudgetCount
								},
								{
									name: 'Draw Requests',
									linkTo: '/drawRequest',
									icon: <TbMailDollar/>,
									count: createdDrawRequestCount
								},
								{
									icon: <FaAddressBook/>,
									name: 'Vendors',
									linkTo: '/vendor'
								},
							]
						}
					]
					: []
			),
			{
				icon: 'file-text',
				name: 'Loan Participations',
				linkTo: '/loanParticipationAgreement'
			},
			{
				icon: 'money',
				name: 'Fund Overview',
				linkTo: '/investor',
			},
			{
				icon: 'tasks',
				name: 'Fund Transactions',
				linkTo: '/investment',
			},
			{
				icon: 'exchange',
				name: 'ACH Transfers',
				linkTo: '/admin/transactions',
			},
			{
				icon: 'file-text-o',
				name: 'Borrower Applications',
				linkTo: '/borrower/application',
			},
			{
				icon: <TbDatabaseDollar/>,
				name: 'Statements',
				subLinks: [
					{
						name: 'Borrower Invoices',
						linkTo: '/statements/borrowerInvoices',
						icon: <LiaFileInvoiceDollarSolid/>,
					},
				],
			},
			{
				icon: 'envelope',
				name: 'Notifications',
				linkTo: '/emailTemplate',
				sideLink: {
					name: 'Add',
					icon: 'plus',
					linkTo: '/emailTemplate/create'
				}
			},
			{
				icon: 'camera',
				name: 'Image Uploader',
				linkTo: '/uploader',
			},
			{
				icon: 'at',
				name: 'Email',
				linkTo: '/message/composeEmail',
			},
			{
				icon: 'cloud-upload',
				name: 'Bulk Imports',
				subLinks: [
					{
						name: 'Fund Transactions',
						linkTo: '/bulkImport/fundImport',
						icon: 'list-ol',
					},
					{
						name: 'Loan Escrows',
						linkTo: '/bulkImport/escrowImport',
						icon: 'list-ol',
					},
					{
						name: 'Sage Loan Servicing',
						linkTo: '/bulkImport/sageLoanServicing',
						icon: 'list-ol',
					},
				]
			},
			{
				icon: 'bars',
				name: 'Other',
				subLinks: [
					{
						icon: 'handshake-o',
						name: 'Ambassador',
						linkTo: '/ambassador',
					},
					...(isNotaryFeatureEnabled ? [{
						icon: <GiStamper size={20}/>,
						name: 'Notary',
						linkTo: '/notary',
					}] : []),
					{
						icon: 'book',
						name: 'Lead Sources',
						linkTo: '/leadSource',
						sideLink: {
							name: 'Add',
							icon: 'plus',
							linkTo: '/leadSource/create'
						}
					},
					{
						icon: 'book',
						name: 'Funding Sources',
						linkTo: '/loanFundingSources',
						sideLink: {
							name: 'Add',
							icon: 'plus',
							linkTo: '/loanFundingSources/create'
						}
					},
					{
						icon: 'book',
						name: 'Title Endorsements',
						linkTo: '/titleEndorsement',
						sideLink: {
							name: 'Add',
							icon: 'plus',
							linkTo: '/titleEndorsement/create'
						}
					},
					{
						icon: 'sort-amount-desc',
						name: 'Superior Liens',
						linkTo: '/loan/superiorLiens',
					},
					{
						icon: 'check-square-o',
						name: 'Features',
						linkTo: '/features',
					},
					{
						icon: 'tasks',
						name: 'Task Templates',
						linkTo: '/taskTemplates',
					},
					{
						icon: 'tasks',
						name: 'Tasks',
						linkTo: '/tasks',
					},
					{
						icon: 'pencil',
						name: 'Content',
						linkTo: '/content'
					},
					{
						icon: 'line-chart',
						name: 'Reports',
						linkTo: '/report'
					},
					{
						icon: 'commenting-o',
						name: 'SMS Whitelist',
						linkTo: '/smsWhitelist'
					},
					{
						icon: <IoAppsOutline/>,
						name: 'Line Item Category',
						linkTo: '/lineItemCategory'
					},
					{
						icon: 'cogs',
						name: 'Advanced Settings',
						linkTo: '/advancedSettings/roles'
					},
					{
						icon: 'cogs',
						name: 'Old Advanced Settings',
						linkTo: '/legacy/admin/advanced',
						restrictedToRoles: ['ROLE_DEVELOPER']
					},
					{
						icon: 'code-fork',
						name: 'Merge Documents',
						linkTo: '/mergeDocument',
					},
					{
						icon: 'percent',
						name: 'Quarterly Returns',
						linkTo: '/quarterlyReturn',
					},
					{
						icon: 'bank',
						name: 'Current Financials',
						linkTo: '/financialInformation'
					},
					{
						icon: 'cloud-upload',
						name: 'Integrations',
						linkTo: '/integration'
					},
					{
						icon: 'history',
						name: 'Email logs',
						linkTo: '/emailLog'
					},
					{
						icon: <GiTemporaryShield/>,
						name: '2FA logs',
						linkTo: '/2FALog'
					},
					{
						icon: 'phone',
						name: 'Voice Call logs',
						linkTo: '/voiceCallLog'
					},
					{
						icon: <GoRocket/>,
						name: 'Release Notes',
						linkTo: '/releaseNotes'
					},
					{
						icon: 'key',
						name: 'System Config',
						linkTo: '/systemConfig',
					},
					{
						icon: 'key',
						name: 'Application Config',
						linkTo: '/applicationConfig',
					}
				]
			},
		];
	}, [submittedBudgetCount, createdDrawRequestCount, isBudgetFeatureEnabled, isNotaryFeatureEnabled]);
};

export default getSideBarLinkObject;
