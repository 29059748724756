import axios from 'axios';
import getAdminApiPath from "@hornet-api/getAdminApiPath";
import ContactCompanyPosition from "@interfaces/ContactCompanyPosition";

const getContactPositionList = async (entityId: number | string) => {
  const url = `${getAdminApiPath()}/entity/${entityId}/contactPosition`;
  const response = await axios.get(url);
  return response.data as ContactCompanyPosition[];
};

export default getContactPositionList;
