import axios from 'axios';
import getAdminApiPath from "@hornet-api/getAdminApiPath";
import {CreateContactData} from "@hornet-api/entities/createContactForEntity";

const createContactForEntity = async (entityId: number, data: CreateContactData) => {
  const url = `${getAdminApiPath()}/entity/${entityId}/members`;
  const res = await axios.post<{ id: number, name: string }>(url, data);
  return res.data;
};

export default createContactForEntity;
