import React, {useEffect} from 'react';
import {Redirect, useParams} from 'react-router-dom';
import {addAlert} from "@components/Alert";

interface RouteGuardProps<Props> {
  Component: React.ComponentType<Props>; // Component with specific props
  validateParams?: (params: Record<string, string | undefined>) => boolean;
  redirectTo?: string;
  alertMessage?: string;
  componentProps?: Props;
}

const RouteGuard = <Props extends {}>(
  {
    Component,
    validateParams = () => true,
    redirectTo = '/',
    alertMessage,
    componentProps,
  }: RouteGuardProps<Props>): JSX.Element => {

  const params = useParams<Record<string, string | undefined>>();

  const isValid = validateParams(params);

  useEffect(() => {
    if (!isValid && alertMessage) {
      addAlert({
        type: 'danger',
        content: alertMessage
      });
    }
  }, [isValid, alertMessage, params]);

  if (!isValid) {
    return <Redirect to={redirectTo}/>;
  }

  return <Component {...(componentProps as Props)} />;
};

export default RouteGuard;
