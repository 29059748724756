import {Button, OverlayTrigger, Tooltip} from "react-bootstrap";
import {FaPencil} from "react-icons/fa6";
import {Link} from "react-router-dom";
import {FaCamera, FaTrash} from "react-icons/fa";
import React from "react";

export type Props = {
  propertyId: number;
  onEditClicked: () => void;
  onDeleteClicked: () => void;
}

const Top = ({propertyId, onEditClicked, onDeleteClicked}: Props) => {
  return (
    <section className="content-header">
      <div className="header-title">
        <OverlayTrigger
          placement="top-end"
          overlay={<Tooltip id={`tt-edit-${propertyId}`}>Edit</Tooltip>}
        >
          <Button
            variant={"primary"}
            className="pull-right"
            onClick={onEditClicked}
          >
            <FaPencil/>
          </Button>
        </OverlayTrigger>
        <OverlayTrigger
          placement="top-end"
          overlay={<Tooltip id={`tt-img-${propertyId}`}>Image</Tooltip>}
        >
          <Link
            className={`btn btn-warning pull-right mr-2`}
            to={`/uploader/show/${propertyId}`}
          >
            <FaCamera/>
          </Link>
        </OverlayTrigger>
        <OverlayTrigger
          placement="top-end"
          overlay={<Tooltip id={`tt-del-${propertyId}`}>Delete</Tooltip>}
        >
          <Button
            variant={"danger"}
            className="pull-right mr-2"
            onClick={onDeleteClicked}
          >
            <FaTrash/>
          </Button>
        </OverlayTrigger>
        <h1>View Property</h1>
        <small>Viewing Properties</small>
      </div>
    </section>
  )
}

export default Top;