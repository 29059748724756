import React, {useEffect, useMemo, useState} from 'react';
import {
  notificationSidebarIsOpenState,
  selectedTaskDetailState,
  selectedTaskIdAndActionTimeStampState,
  selectedTaskState
} from '../globalState';
import {deleteChat, getTaskOwnerName, refreshTaskDetails, setIsLoading, triggerTaskRefresh} from '../utils';
import isAdminState from "@state/globalState/isAdminState";

import {FaCheck, FaChevronLeft, FaCircleNotch, FaPlus, FaTrash} from 'react-icons/fa';
import classNames from 'classnames';
import style from '../NotificationTasks.module.scss';
import Messages from './Messages';
import MessageInput from './MessageInput';
import {addFileModalState} from './AddFileModal';
import {TaskStatusEnum} from '@interfaces/GeneratedEnums';
import updateTask from '@hornet-api/task/updateTask';
import getMessages, {GetMessageOptions} from '@hornet-api/task/getMessages';
import attachFile, {getLinkUrl} from '@hornet-api/task/attachFile';
import {Attachment, ChatMessage, Message} from '@interfaces/task';
import deleteTask, {DeleteTaskOpts} from "@hornet-api/task/deleteTask";
import {Link} from "react-router-dom";
import ReactQuill from "react-quill";
import _ from "lodash";
import {loadingRelease, loadingTrigger} from "@components/LoadingOverlay";
import TaskAttachment from "@components/NotificationSidebar/components/TaskAttachment";
import {alertApiErrors} from "@common/errors";
import ChatFileDropZone from "@components/NotificationSidebar/components/ChatFileDropZone";
import FileDropZone from "@components/NotificationSidebar/components/FileDropZone";
import {addAlert} from "@components/Alert";
import {pluralize} from "@common/utils/stringUtil";


const TaskDetail = () => {
  const [task, setTask] = selectedTaskDetailState.use();
  const isSidebarOpen = notificationSidebarIsOpenState.useValue();

  const [messageToEdit, setMessageToEdit] = useState(null as null | ChatMessage)
  const [isDeleting, setIsDeleting] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const isAdmin = isAdminState.useValue();
  const [messages, setMessages] = useState(null as null | Message[]);
  const selectedTask = selectedTaskState.useValue();


  const generateDocumentLinkUrl = (doc: Attachment) => {
    return getLinkUrl({
      contactId: isAdmin ? contactId : undefined,
      companyId: isAdmin ? companyId : undefined,
      contactCompanyId: !isAdmin ? companyId : undefined,
      taskId: task?.id!,
      attachmentId: doc.id,
      download: true
    })
  }

  useEffect(() => {
    if (isSidebarOpen && selectedTask) {
      refreshTaskDetails(true);
    }
  }, [isSidebarOpen, selectedTask])

  const updateMessages = _.debounce(async () => {
    if (!task) return;
    const t = loadingTrigger();
    try {
      let opts: GetMessageOptions = {
        taskId: task.id,
      }
      if (isAdmin) {
        if ('contact' in task) opts.contactId = task.contact;
        if ('entity' in task) opts.companyId = task.entity;
      } else if ('entity' in task) {
        opts.contactCompanyId = task.entity;
      }
      const m = await getMessages(opts);
      setMessages(m);
    } catch (e) {
      console.error('Error loading task messages', e);
    }
    loadingRelease(t);
  }, 100);

  const deleteThisTask = async () => {
    if (!task || isDeleting) return;
    setIsDeleting(true);
    let opts: DeleteTaskOpts = {
      taskId: task.id,
    }
    if (isAdmin) {
      if ('contact' in task) opts.contactId = task.contact;
      if ('entity' in task) opts.companyId = task.entity;
    } else if ('entity' in task) {
      opts.contactCompanyId = task.entity;
    }
    try {
      await deleteTask(opts);
      setIsDeleting(false);
      setTask(null);
      selectedTaskState.set(null);
      triggerTaskRefresh();
    } catch (e) {
      setIsDeleting(false);
      alert('Something went wrong');
    }
  }

  const messagesMemo = useMemo(() => messages || [], [messages]);

  useEffect(() => {
    if (task) {
      updateMessages();
    }
  }, [task, isAdmin]);

  const description = useMemo(() => {
    if (!task) return null;

    return (
      <ReactQuill
        modules={{toolbar: false}}
        value={task.description}
        readOnly
        className={'show-only mb-2'}
      />
    )
  }, [task])

  const loanLink = useMemo(() => {
    if (!task || !('loanId' in task)) return null;
    let url;
    if (isAdmin) {
      url = `/loan/show/${task?.loanId}`
    } else {
      url = `/userLoan/show/${task?.loanId}`
    }
    return (
      <Link
        to={url}
        onClick={() => {
          notificationSidebarIsOpenState.set(false);
        }}
      >
        {task.loanId}
      </Link>
    );
  }, [task, isAdmin]);

  if (!task) {
    return null;
  }
  const ownerName = getTaskOwnerName(task);
  const contactId = 'contact' in task ? task.contact : undefined;
  const companyId = 'entity' in task ? task.entity : undefined;

  // show the task

  const onDropTaskAttachment = (files: File[]) => {
    setIsLoading(true);
    attachFile({
      companyId: isAdmin ? companyId : undefined,
      contactId: isAdmin ? contactId : undefined,
      contactCompanyId: !isAdmin ? companyId : undefined,
      files: files,
      taskId: task.id,
    })
      .then(() => {
        addAlert({
          type: 'success',
          content: `${pluralize('File', files.length)} uploaded successfully.`
        })
        return refreshTaskDetails(true)
      })
      .catch(alertApiErrors)
      .finally(() => {
        setIsLoading(false)
      });
  }

  return (
    <div className={isAdmin ? classNames(style.isAdmin, style.task) : style.task}>
      <div className={style.top}>
        <div
          className={style.left}
          onClick={() => {
            selectedTaskState.set(null);
            triggerTaskRefresh();
          }}
        >
          <FaChevronLeft/> BACK
        </div>
        <div className={isAdmin ? classNames(style.right, style.isAdmin) : style.right}>
          {
            isAdmin ?
              <>
                <div className={style.selectBox}>
                  <select
                    className="form-control"
                    value={task.taskStatus}
                    onChange={async (e) => {
                      const data: any = {
                        taskId: task.id,
                        taskStatus: e.target.value
                      };
                      if ('contact' in task) data.contactId = task.contact;
                      if ('entity' in task) data.companyId = task.entity;
                      const t = await updateTask(data);
                      setTask(t);
                    }}
                  >
                    {
                      Object.keys(TaskStatusEnum).map((key, index) => {
                        return (
                          <option value={key} key={index}>
                            {/* @ts-ignore */}
                            {TaskStatusEnum[key]}
                          </option>
                        );
                      })
                    }
                  </select>
                </div>
                <div
                  className={style.menuBtn}
                  onClick={() => {
                    if (confirm("Are you sure you want to delete this task? This cannot be undone.")) {
                      deleteThisTask();

                    }
                  }}
                >
                  {isDeleting ? <FaCircleNotch className={'spin'}/> : <FaTrash/>}
                </div>
              </>
              :
              task.taskStatus === 'ACTIVE' ?
                <button
                  className={classNames(['btn', 'btn-light', style.btn])}
                  disabled={isSubmitting}
                  onClick={async () => {
                    if (isSubmitting) return;
                    setIsSubmitting(true);
                    let data: any = {
                      taskId: task.id,
                      taskStatus: 'PENDING'
                    };
                    if ('entity' in task) data.contactCompanyId = task.entity;
                    const t = await updateTask(data);
                    setTask(t);
                    selectedTaskIdAndActionTimeStampState.set({id: task?.id, timeStamp: Date.now()})
                    setIsSubmitting(false);
                  }}
                >
                  {isSubmitting ? <FaCircleNotch className="spin"/> : <FaCheck/>} Submit
                </button>
                :
                <div>{TaskStatusEnum[task.taskStatus]}</div>
          }
        </div>
      </div>
      <div style={{position: 'relative'}}>
        <FileDropZone
          onDropFiles={onDropTaskAttachment}
          message={'Drop the task related files here ...'}
        />
        <div className={style.descriptionBox}>
          {
            isAdmin ?
              <div className={style.ownerName}>
                {ownerName}
              </div>
              :
              null
          }
          <div className={style.title}>
            {task.title}
          </div>
          <div className={style.content}>
            {description}
          </div>
          {
            task.loanId ?
              <div className={style.loanId}>
                LOAN: {loanLink}
              </div>
              :
              null
          }
          <hr/>
        </div>
        <div className={style.subBox}>
          <div className={style.subTop}>
            <div className={style.left}>
              <div className={style.subTitle}>
                Attachments
              </div>
            </div>
            <div
              className={style.addFile}
              onClick={() => {
                addFileModalState.set({
                  companyId: isAdmin ? companyId : undefined,
                  contactId: isAdmin ? contactId : undefined,
                  contactCompanyId: !isAdmin ? companyId : undefined,
                  files: null,
                  taskId: task.id,
                  onClose: refreshTaskDetails,
                })
              }}
            >
              <FaPlus/> ADD FILES
            </div>
          </div>
        </div>
      </div>
      <div className={style.subBox}>
        {
          task.templateAttachments.map((doc, index) => {
            const url = generateDocumentLinkUrl(doc)
            return (
              <div key={index}><a href={url} target="_blank">{doc.fileName}</a></div>
            );
          })
        }
        {
          task.attachments.map((doc, index) => (
            <TaskAttachment
              doc={doc} key={index} task={task} contactId={contactId} companyId={companyId}
              isAdmin={isAdmin} isSidebarOpen={isSidebarOpen}
            />
          ))
        }
      </div>
      <hr/>
      <div style={{position: 'relative', flexGrow: 1, minHeight: '450px'}}>
        <Messages
          task={task}
          isResolved={task.chat.isResolved}
          messages={messagesMemo}
          onSubmitted={async () => {
            await triggerTaskRefresh();
            await Promise.all([refreshTaskDetails(), updateMessages()]);
          }}
          messageToEdit={messageToEdit}
          onEdit={(message) => setMessageToEdit(message)}
          onDelete={(message) =>
            message &&
            deleteChat(message.id)
              .then(() => refreshTaskDetails(true))
              .catch(alertApiErrors)
          }
          onDeleteChatAttachment={() => {
            refreshTaskDetails(true).then()
          }}
        />
        <MessageInput
          chat={task.chat}
          messageToEdit={messageToEdit}
          onSubmitted={async () => {
            setMessageToEdit(null);
            await triggerTaskRefresh();
            await Promise.all([refreshTaskDetails(), updateMessages()]);
          }}
        />
        {isAdmin && <ChatFileDropZone chatId={task.chat.id} onClose={refreshTaskDetails}/>}
      </div>
    </div>
  );
}

export default TaskDetail;
