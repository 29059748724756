import React, {useEffect, useMemo, useRef, useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import {createGlobalState} from 'react-global-hooks';
import {FaCaretDown, FaCircleNotch} from 'react-icons/fa';
import numeral from 'numeral';
import style from './AchConfirmModal.module.scss';
import {wiringObject, WiringObject} from './AchConfirmModalUtils';
import Transfer from '@interfaces/Transfer';
import authorizeTransaction from '@hornet-api/banking/authorizeTransaction';
import {refreshTransactions} from '@components/NotificationSidebar/utils';
import {ACHStatusEnum} from '@interfaces/GeneratedEnums';
import {transfersState} from '@components/NotificationSidebar/globalState';
import {
  addEditBankAccountModalState,
  openAddEditBankAccountModal
} from "@components/modals/AddEditBankModal/AddEditBankAccountModal";
import {alertApiErrors} from "@common/errors";
import DecryptButton from "@admin-ui/components/DecryptButton";
import {addAlert} from "@components/Alert";
import {formatDate} from "@common/basic";
import {refreshRecurringPayments} from "@legacy/views/RecurringPaymentsView/global";
import {payerTokenState} from "@pages/OneTimeLoanPaymentPage/global";
import getMessageByCategory from "@hornet-api/message/getMessageByCategory";
import {loadingRelease, loadingTrigger} from "@components/LoadingOverlay";
import {FaRegMoneyBill1} from 'react-icons/fa6';

export interface AchConfirmModalObj extends Transfer {
  onConfirm?: () => void | Promise<void>;
  onCancel?: () => void | Promise<void>;
  editMode?: boolean;
}

export const achModalState = createGlobalState(null as AchConfirmModalObj | null);

export const startAchTransfer = (props: AchConfirmModalObj) => {
  achModalState.set(props);
};

const AchConfirmModal = () => {
  // It's set when a payer is making the payment with a public token
  const payerToken = payerTokenState.useValue();
  const [achModal, setAchModal] = achModalState.use();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isCancel, setIsCancel] = useState(false);
  const editBankAccount = addEditBankAccountModalState.useValue();
  const [manualIsLoading, setManualIsLoading] = useState(false);
  const [showWiringInstructions, setShowWiringInstructions] = useState(false);
  const transfers = transfersState.useValue();
  const [htmlText, setHtmlText] = useState<string>("");


  /**
   * we need this in case bank account is updated
   */
  const currentTransfer = useMemo(() => {
    return transfers?.find(t => t.id === achModal?.id);
  }, [transfers, achModal?.id]);

  const onlyDetails = useMemo(() => {
    if (!achModal) return false;
    const detailOnlyArr: (keyof typeof ACHStatusEnum)[] = [
      'ACH_COMPLETE',
      'WIRE_COMPLETE',
      'ACH_AUTHORIZED'
    ];
    return detailOnlyArr.includes(achModal.transactionStatus);
  }, [achModal]);

  const ref = useRef();

  useEffect(() => {
    if (!achModal || htmlText){
      return;
    }
    const t = loadingTrigger();

    getMessageByCategory("EDIT_WIRE_INSTRUCTIONS_MODAL").then((res) => {
      setHtmlText(res.content)
    }).finally(() => loadingRelease(t));
  }, [achModal, htmlText]);


  useEffect(() => {
    if (currentTransfer && achModal) {
      if (currentTransfer.transactionStatus === 'WIRE_TRANSFER_PENDING') {
        // set the modal to show wiring instructions when wire transaction is pending on load
        setShowWiringInstructions(true);
      }
      currentTransfer.scheduledDate ??=
        currentTransfer.achCategory === 'LPA_ACH' ? achModal.scheduledDate : currentTransfer.scheduledDate;
      // We don't have to decrypt, show the updated transfer
      setAchModal({
        ...achModal,
        ...currentTransfer
      });
    }
  }, [currentTransfer]);

  const handleBoth = () => {
    setAchModal(null);
    setIsSuccess(false);
    setIsCancel(false);
    setShowWiringInstructions(false);
  };

  const handleCancel = () => {
    handleBoth();
    achModal?.onCancel?.();
  };
  const handleSuccessClose = () => {
    handleBoth();
    achModal?.onConfirm?.();
  };

  const handleConfirm = async () => {
    if (isSubmitting || !achModal) return;
    setIsSubmitting(true);
    setShowWiringInstructions(false);

    try {
      await authorizeTransaction(achModal.id, 'ACH_AUTHORIZED', achModal?.scheduledDate);
      await refreshTransactions();
      if (achModal?.onConfirm) {
        return achModal.onConfirm();
      }
      addAlert({
        type: 'success',
        content: 'ACH transfer has been successfully submitted.'
      })
    } catch (e) {
      console.log(e)
      alertApiErrors(e);
    } finally {
      setIsSuccess(true);
      setIsSubmitting(false);
      handleSuccessClose();
    }
  };

  const show = achModal !== null && editBankAccount === null;

  const deps: React.DependencyList = [
    htmlText,
    achModal,
    isSuccess,
    isCancel,
    isSubmitting,
    showWiringInstructions,
    manualIsLoading,
    editBankAccount,
    payerToken
  ];

  console.log(achModal, 'achModal')

  const instructionsObject = useMemo(() => {
    return (
      <div className={style.instructions}>
        <a
          href="#"
          onClick={(event) => {
            event.preventDefault();
            if (manualIsLoading || !achModal) return;
            setManualIsLoading(true);
            // switch the status to wire transfer pending
            authorizeTransaction(achModal.id, 'WIRE_TRANSFER_PENDING')
              // update the main state for taskbar
              .then(refreshTransactions)
              .catch((e) => {
                console.error(e);
                alertApiErrors(e);
              })
              .finally(() => {
                setManualIsLoading(false);
                // update transfer in the state
                setShowWiringInstructions(true);
              });
          }}
        >
          {manualIsLoading ? <FaCircleNotch className={'spin'}/> : null} Manually Wire Funds
        </a>
      </div>
    );
  }, [deps]);

  /** MODEL HEADER */
  const modelHeader = useMemo(() => {
    if (editBankAccount) {
      return <>Edit Bank Account</>
    }
    if (showWiringInstructions) {
      return <>Wire and ACH Instructions</>;
    }
    if (isSuccess) {
      return <span className="text-success">Success!</span>;
    }
    if (isCancel) {
      return (
        <>
          ACH Bank Transfer <small>(INCOMPLETE)</small>
        </>
      );
    }
    return <>ACH Bank Transfer</>;
  }, deps);

  /** MODEL BODY */
  const modalBody = useMemo(() => {
    if (!achModal) return null;
    if (showWiringInstructions) {
      const type: keyof WiringObject = achModal.lpa ? 'lpa' : 'sub';
      const instructions = wiringObject[type];
      return (
        <div className={style.instructions}>
          <div className={style.name}>{instructions.name}</div>
          <div className={style.downloadBox}>
            <a href={instructions.download} download target='_blank'>Download Wiring Instructions</a>
          </div>
          <div>
            <Modal.Body>
              <div>
                <p><strong>Amount:</strong> ${numeral(achModal.amount).format('0,0.00')}</p>
              </div>
              <div dangerouslySetInnerHTML={{__html: htmlText}}></div>
            </Modal.Body>
          </div>
        </div>
      );
    }
    if (isSuccess) {
      return (
        <>
          We have initiated an ACH transfer for the amount of{' '}
          <strong>${numeral(achModal.amount).format('0,0.00')}</strong> from{' '}
          <strong>{achModal.bank.bankNameFormatted}</strong>.
        </>
      );
    }
    if (isCancel) {
      return (
        <>
          <strong className="text-warning">Transfer Incomplete!</strong> You can complete this
          transfer at any time from <strong>Resources<FaCaretDown/> &#8594; Bank<FaCaretDown/> &#8594; <FaRegMoneyBill1/> Transactions</strong>
        </>
      );
    }
    if (onlyDetails) {
      return (
        <>
          The transfer for the amount of{' '}
          <strong>${numeral(achModal.amount).format('0,0.00')}</strong> from{' '}
          <strong>{achModal.bank.bankNameFormatted} </strong>
          is currently in status <strong>{ACHStatusEnum[achModal.transactionStatus]}</strong>. No
          further action is needed.
        </>
      );
    }
    return (
      <>
        {
          achModal.transactionStatus === 'ACH_ERROR' ?
            <>
              <div className="text-danger" style={{fontWeight: 600}}>ACH Transfer Failed</div>
              Something went wrong with your ACH transfer and it requires assistance.
            </>
            :
            <>
              Please verify the following ACH bank transfer information and authorize the transfer.
            </>
        }
        <div className={style.bankInfo}>
          <table>
            <tbody>
            <tr>
              <th>Amount:</th>
              <td>${numeral(achModal.amount).format('0,0.00')}</td>
            </tr>
            <tr>
              <th>Bank Name:</th>
              <td>{achModal.bank.bankName}</td>
            </tr>
            <tr>
              <th>Payment Date:</th>
              <td>{formatDate(achModal.scheduledDate)}</td>
            </tr>
            <tr>
              <th>Account:</th>
              <td><DecryptButton encryptedString={achModal.bank.accountNumber}/></td>
            </tr>
            <tr>
              <th>Routing:</th>
              <td><DecryptButton encryptedString={achModal.bank.routingNumber}/></td>
            </tr>
            </tbody>
          </table>
        </div>
        {payerToken && (
          <div className={'text-center'}>
            <Button
              variant={'link'}
              className={'py-0'}
              onClick={() => {
                openAddEditBankAccountModal({
                  id: achModal.bank.id,
                  isACHBankEditing: true,
                  onComplete: (bankAccount) => {
                    setAchModal({
                      ...achModal,
                      bank: {
                        company: bankAccount.entity,
                        ...bankAccount,
                        bankNameFormatted: `${bankAccount.bankNameFormatted}`,
                        ...bankAccount.accountInformation,
                      }
                    });
                    refreshTransactions()
                      .catch(alertApiErrors);
                  }
                });
              }}
            >
              Edit Account Information
            </Button>
          </div>
        )}
      </>
    );
  }, deps);

  /** MODEL FOOTER */
  const modalFooter = useMemo(() => {
    if (editBankAccount) {
      return null;
    }
    if (showWiringInstructions) {
      return (
        <>
          <div className={style.instructions}>
            <a
              href="#"
              onClick={(event) => {
                event.preventDefault();
                if (manualIsLoading || !achModal) return;
                setManualIsLoading(true);
                // switch the status to wire transfer pending
                authorizeTransaction(achModal.id, 'ACH_PENDING_AUTHORIZATION', achModal.scheduledDate)
                  .then(refreshRecurringPayments)
                  // update the main state for taskbar
                  .then(refreshTransactions)
                  .catch((e) => {
                    console.error(e);
                    alertApiErrors(e);
                  })
                  .finally(() => {
                    setManualIsLoading(false);
                    // update transfer in the state
                    setShowWiringInstructions(false);
                  });
              }}
            >
              {manualIsLoading ? <FaCircleNotch className={'spin'}/> : null} Switch to Automatic
              Transfer
            </a>
          </div>
          <Button
            variant="secondary"
            onClick={() => {
              handleCancel();
            }}
          >
            Close
          </Button>
        </>
      );
    }
    if (isSuccess) {
      return (
        <>
          <Button variant="primary" onClick={handleSuccessClose}>
            Done
          </Button>
        </>
      );
    }
    if (isCancel) {
      return (
        <>
          <Button
            variant="secondary"
            onClick={() => {
              handleCancel();
            }}
          >
            Ok
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              setIsCancel(false);
            }}
          >
            Go Back
          </Button>
        </>
      );
    }
    if (onlyDetails) {
      return (
        <>
          <Button
            variant="secondary"
            onClick={() => {
              handleCancel();
            }}
          >
            Close
          </Button>
        </>
      );
    }
    return (
      <>
        {!achModal?.isRecurring && !payerToken && instructionsObject}
        {
          !achModal?.editMode && !payerToken ? (
            <Button
              variant="secondary"
              onClick={() => {
                if (achModal?.transactionStatus === 'ACH_ERROR') {
                  handleCancel();
                } else {
                  setIsCancel(true);
                }
              }}
            >
              Save as Draft
            </Button>
          ) : null
        }
        {
          achModal?.transactionStatus !== 'ACH_ERROR' ?
            <Button variant="primary" onClick={handleConfirm} disabled={isSubmitting}>
              {isSubmitting ? <FaCircleNotch className="spin"/> : 'Submit'}
            </Button>
            :
            null
        }
      </>
    );
  }, deps);

  return (
    <>
      <Modal
        show={show}
        onHide={async () => {
          if (isSuccess) {
            await handleSuccessClose();
          } else {
            await handleCancel();
          }
        }}
        id={'ach-confirm-modal'}
        onEntered={() => {
        }}
        // size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>{modelHeader}</Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            minHeight: 200
          }}
        >
          {modalBody}
        </Modal.Body>
        <Modal.Footer>{modalFooter}</Modal.Footer>
      </Modal>
    </>
  );
};

export default AchConfirmModal;