import axios from "axios";
import getMetaApiPath from "@hornet-api/getMetaApiPath";

export interface AvailableTwoFactorOption {
  twoFaPhone: string
}

export const availableTwoFactorOptions = async () => {
  const url = `${getMetaApiPath()}/2fa/available-two-factor-options`;
  return axios.get<AvailableTwoFactorOption>(url).then(res => res.data
  )
};