import React, {useEffect, useMemo, useState} from "react";
import {Button, Card} from "react-bootstrap";
import BootstrapSmartTable from "@components/BootstrapSmartTable";
import {IInsurance} from "@interfaces/insurance";
import {convertInsuranceDataToTableData, columns} from "@admin-ui/pages/InsurancePage/tableData";
import {getInsuranceListForProperty} from "@hornet-api/insurance";
import {alertApiErrors} from "@common/errors";
import AddEditInsuranceModal, {
  openAddEditInsuranceModal
} from "@admin-ui/pages/InsurancePage/components/AddEditInsuranceModal";
import TitleEndorsement from "@interfaces/TitleEndorsement";
import {loadingRelease, loadingTrigger} from "@components/LoadingOverlay";
import getTitleEndorsementList from "@hornet-api/titleEndorsement/getTitleEndorsementList";


type Props = {
  propertyId: number;
  aliasIds: string[];
}


const Insurance = ({propertyId, aliasIds}: Props) => {
  const [isLoading, setLoading] = useState(false);
  const [insuranceList, setInsuranceList] = useState<IInsurance[]>([]);
  const [titleEndorsements, setTitleEndorsements] = useState<TitleEndorsement[]>([]);

  const refreshData = () => {
    setLoading(true);
    getInsuranceListForProperty(propertyId)
      .then(setInsuranceList)
      .catch(alertApiErrors)
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    const t = loadingTrigger();
    getTitleEndorsementList()
      .then(setTitleEndorsements)
      .catch(alertApiErrors)
      .finally(() => loadingRelease(t));
  }, []);

  useEffect(refreshData, [propertyId]);

  const titleEndorsementMap = useMemo(() => {
    return titleEndorsements.reduce((map, endorsement) => {
      map[endorsement.id] = endorsement.title;
      return map;
    }, {} as { [key: number]: string });
  }, [titleEndorsements]);

  const tableData = useMemo(() => convertInsuranceDataToTableData(insuranceList, titleEndorsementMap), [insuranceList, titleEndorsementMap]);

  return (
    <Card>
      <Card.Header>
        Insurance
        <Button
          className={'pull-right'}
          size={'sm'}
          onClick={() => openAddEditInsuranceModal({
            aliasIds: aliasIds,
            propertyIds: [propertyId],
            onComplete: refreshData
          })}
        >
          Add New
        </Button>
      </Card.Header>
      <Card.Body>
        <BootstrapSmartTable
          isLoading={isLoading}
          name={'PropertyInsuranceListPage'}
          columns={columns}
          data={tableData}
        />
      </Card.Body>
      <AddEditInsuranceModal/>
    </Card>
  )
}

export default Insurance;
