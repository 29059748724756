import axios from 'axios';
import PhoneNumber from "@interfaces/PhoneNumber";
import getAdminApiPath from "@hornet-api/getAdminApiPath";

const addPhoneNumber = async (contactId: number | string, data: PhoneNumber) => {
  const url = `${getAdminApiPath()}/contact/${contactId}/phoneNumber`;
  const res = await axios.post<PhoneNumber>(url, data);
  return res.data;
};

export default addPhoneNumber;
