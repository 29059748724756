import axios from 'axios';
import getAdminApiPath from '../getAdminApiPath';
import TitleEndorsement from "@interfaces/TitleEndorsement";

const getTitleEndorsementList = async () => {
  const url = `${getAdminApiPath()}/titleEndorsement`
  const response = await axios.get(url);
  return response.data as TitleEndorsement[];
};

export default getTitleEndorsementList;
