import {useEffect, useRef} from 'react';
import Store from '../globalState';

// this class prevents rapid firing which will lock up browser while resizing
// it also updates the states
class Resizer<D> {
  constructor(
    store: Store<D>,
    mobileBreakPoint = 1074
  ) {
    this.store = store;
    this.mobileBreakPoint = mobileBreakPoint
  }

  private store: Store<D>;
  private mobileBreakPoint: number;
  private isResizing = false;
  private triggerAgain = false;
  private timeoutMs = 500;

  private updateStates = () => {
    try {
      // mobile
      if (window.innerWidth < this.mobileBreakPoint) {
        // is mobile
        if (!this.store.windowIsMobileState.get()) {
          this.store.windowIsMobileState.set(true);
        }
      } else {
        // is not mobile
        if (this.store.windowIsMobileState.get()) {
          this.store.windowIsMobileState.set(false);
        }
      }
    } catch (e) {
      console.error(e);
    }
  }

  public handleResize = () => {
    if (this.isResizing) {
      // already in resize mode set flag to trigger again
      this.triggerAgain = true;
    } else {
      this.updateStates();
      setTimeout(() => {
        this.isResizing = false;
        if (this.triggerAgain) {
          this.triggerAgain = false;
          this.handleResize();
        }
      }, this.timeoutMs);
    }
  }
}

type Props<D> = {
  store: Store<D>,
  mobileBreakPoint?: number
}

function ResizeMonitor<D>(
  {
    store,
    mobileBreakPoint = 1074
  }: Props<D>
) {

  const resizerRef = useRef(new Resizer(store, mobileBreakPoint));

  useEffect(() => {
    // do a handle of resize on start
    resizerRef.current.handleResize();
    const eventListenFn = () => {
      resizerRef.current.handleResize();
    };
    window.addEventListener('resize', eventListenFn);
    return () => {
      window.removeEventListener('resize', eventListenFn)
    }
  });
  return null;
}

export default ResizeMonitor;