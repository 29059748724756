import axios from "axios";
import getUserApiPath from "@hornet-api/getUserApiPath";
import {NotaryDocumentFormData} from "@admin-ui/pages/OneNotaryPage/components/OneNotaryDetails/AddDocumentModal";
import {IOneNotary, IOneNotaryDocument} from "@interfaces/one-notary";
import {INotarySession} from "@legacy/views/UserNotary/components/NotaryList";

const checkNotaryEligibility = async () => {
  const url = `${getUserApiPath()}/oneNotary/eligibility`;
  const res = await axios.get<{ isEligible: boolean }>(url);
  return res.data.isEligible;
}

const getSessionList = async () => {
  const url = `${getUserApiPath()}/oneNotary`;
  const res = await axios.get<INotarySession[]>(url);

  return res.data.map(data => ({
    ...data,
    documentNames: data.documents.map(doc => doc.name).join("|"),
  }));
};

const getSessionDetails = async (sessionId: string) => {
  const url = `${getUserApiPath()}/oneNotary/${encodeURIComponent(sessionId)}`;
  const res = await axios.get<INotarySession>(url);
  return res.data;
};

const createNewSession = async (isPrivate: boolean) => {
  const url = `${getUserApiPath()}/oneNotary`;
  const res = await axios.post<IOneNotary>(url, {isPrivate});
  return res.data;
};

const initSession = async (sessionId: string, createTaskFlag: boolean) => {
  const url = `${getUserApiPath()}/oneNotary/${encodeURIComponent(sessionId)}/init`;
  const res = await axios.post(url, {createTaskFlag});
  return res.data;
};

const cancelSession = async (sessionId: string) => {
  const url = `${getUserApiPath()}/oneNotary/${encodeURIComponent(sessionId)}/cancelSession`;
  const res = await axios.post(url);
  return res.data;
};

const addDocument = async (sessionId: string, notaryDocumentFormData: NotaryDocumentFormData) => {
  const formData = new FormData();
  formData.append('file', notaryDocumentFormData.file!);

  const url = `${getUserApiPath()}/oneNotary/${encodeURIComponent(sessionId)}/documents`;
  const res = await axios.post<IOneNotaryDocument>(url, formData);
  return res.data;
};

const updateDocumentName = async (sessionId: string, documentId: string, overrideName: string) => {
  const url = `${getUserApiPath()}/oneNotary/${encodeURIComponent(sessionId)}/documents/${documentId}`;
  return axios.patch(url, {overrideName});
};

const deleteDocument = async (sessionId: string, documentId: string) => {
  const url = `${getUserApiPath()}/oneNotary/${encodeURIComponent(sessionId)}/documents/${documentId}`;
  return axios.delete(url);
};

const downloadDocumentUrl = (sessionId: string, documentId: string) => {
  return `${getUserApiPath()}/oneNotary/${encodeURIComponent(sessionId)}/documents/${documentId}/signed/download`;
};

const downloadDocumentsUrl = (sessionId: string) => {
  return `${getUserApiPath()}/oneNotary/${encodeURIComponent(sessionId)}/documents/download`;
}

const getInvitationInfoForPrimarySigner = async (sessionId: string) => {
  const url = `${getUserApiPath()}/oneNotary/${encodeURIComponent(sessionId)}/participants/invitation/primary`;
  const res = await axios.get<{ link: string; }>(url);
  return res.data;
};

const setNotaryVisibility = async (sessionId: string, isPrivate: boolean) => {
  const url = `${getUserApiPath()}/oneNotary/${encodeURIComponent(sessionId)}/visibility`;
  return axios.patch(url, {isPrivate: isPrivate});
};


export {
  checkNotaryEligibility,
  getSessionList,
  getSessionDetails,
  createNewSession,
  initSession,
  cancelSession,
  addDocument,
  updateDocumentName,
  deleteDocument,
  downloadDocumentUrl,
  downloadDocumentsUrl,
  getInvitationInfoForPrimarySigner,
  setNotaryVisibility,
}
