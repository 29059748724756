import React from 'react';
import FileDropZone from "@components/NotificationSidebar/components/FileDropZone";
import {uploadChatFiles} from "@components/NotificationSidebar/utils";

interface FileDropZoneProps {
  chatId: number;
  onClose?: () => void;
}

const ChatFileDropZone = ({chatId, onClose}: FileDropZoneProps) => {

  const onDropChatFiles = (files: File[]) => {
    uploadChatFiles(files, chatId.toString())
      .then(onClose);
  }

  return (
    <FileDropZone message={'Drop the chat related files here'} onDropFiles={onDropChatFiles}/>
  );
};

export default ChatFileDropZone;
