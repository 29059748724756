import React, {useEffect, useMemo, useState} from 'react';
import {useHistory, useParams} from "react-router-dom";
import style from "@admin-ui/pages/PropertyPage/PropertyPage.module.scss";
import AddEditPropertyModal, {openAddEditPropertyModal} from "@admin-ui/pages/PropertyPage/AddEditPropertyModal";
import {PropertyServer} from "@interfaces/Property";
import DocumentUploadModal from "@admin-ui/components/DocumentUploadModal";
import PropertyDocuments from "@admin-ui/pages/PropertyPage/show/PropertyDocuments";
import AddEditTaxRecordModal from "@admin-ui/pages/PropertyPage/show/AddEditTaxRecordModal";
import {PropertyTaxes} from "@admin-ui/pages/PropertyPage/show/PropertyTaxes";
import {PropertyDetailsShow} from "@admin-ui/pages/PropertyPage/show/PropertyDetailsShow";
import {PropertyInformationShow} from "@admin-ui/pages/PropertyPage/show/PropertyInformationShow";
import {LegalAddress} from "@admin-ui/pages/PropertyPage/show/LegalAddress";
import {AssociatedLoans} from "@admin-ui/pages/PropertyPage/show/AssociatedLoans";
import {getPropertyById} from "@hornet-api/properties/getProperty";
import {alertApiErrors} from "@common/errors";
import {loadingRelease, loadingTrigger} from "@components/LoadingOverlay";
import {deletePropertyById} from "@hornet-api/properties/deleteProperty";
import {addAlert} from "@components/Alert";
import {refreshPropertyList} from "@components/NotificationSidebar/utils";
import {LoanStageEnum} from "@admin/enum/LoanStageEnum";
import {BorrowerApplicationStatusEnum} from "@interfaces/GeneratedEnums";
import {prettyList} from "@common/basic";
import Insurance from "@admin-ui/pages/PropertyPage/show/Insurance";
import Top from "@admin-ui/pages/PropertyPage/show/Top";
import {Tab, Tabs} from "react-bootstrap";
import '../PropertyPage.scss';
import AddEditEntityModal from "@admin-ui/pages/EntityPage/components/AddEditEntityModal";

type ShowPropertyUrlParams = {
  id: string
}
const ShowPropertyModal = () => {
  const {id} = useParams() as ShowPropertyUrlParams;
  const [property, setProperty] = useState<PropertyServer>();
  const history = useHistory();

  useEffect(() => {
    if (id) {
      const t = loadingTrigger();
      getPropertyById(id)
        .then(setProperty)
        .catch(alertApiErrors)
        .finally(() => loadingRelease(t));
    }
  }, [id])

  const propertyAssociation = useMemo(() => {
    if (!property) return;
    let isPropertyDeletable = true;
    let content = "";
    const keysOfInterest = ["LOANS", "DEED", "BPA", "BPA_SNAPSHOT"];
    for (const key in property.blockingRows) {
      if (keysOfInterest.includes(key) && property.blockingRows[key].length > 0) {
        const rows = property.blockingRows[key];
        switch (key) {
          case "LOANS":
            const loanContent = rows.reduce((acc: string, row) => `${acc} ${row.name} (${row.status ? LoanStageEnum[row.status as keyof typeof LoanStageEnum] : ''}),`, "");
            content += `Loan(s): ${loanContent}`
            isPropertyDeletable = false;
            break;
          case "BPA":
            const bpaContent = rows.reduce((acc: string, row) => `${acc} ${row.name} (${row.status ? BorrowerApplicationStatusEnum[row.status as keyof typeof BorrowerApplicationStatusEnum] : ''}),`, "");
            content += `BPA(s): ${bpaContent}`
            break;
          case "BPA_SNAPSHOT":
            const bpaSnapshotContent = rows.reduce((acc: string, row) => `${acc} ${row.name} (${row.status ? BorrowerApplicationStatusEnum[row.status as keyof typeof BorrowerApplicationStatusEnum] : ''}),`, "");
            content += `BPA snapshot(s): ${bpaSnapshotContent}`
            break;
          case "DEED":
            content += `${rows.length} Deed of trust(s),`;
            isPropertyDeletable = false;
            break;
        }
      }
    }
    const parts = content.split(',').filter(part => part.trim() !== '');
    return {isPropertyDeletable, content: prettyList(parts)};
  }, [property]);

  const onEditClicked = () => {
    openAddEditPropertyModal({
      onComplete: (data: PropertyServer) => {
        addAlert({
          type: 'success',
          content: "Property updated successfully."
        });
        setProperty(data);
      },
      id: Number(id)
    });
  }
  const onDeleteClicked = () => {
    if (!property) return
    if (propertyAssociation && !propertyAssociation.isPropertyDeletable) {
      addAlert({
        type: 'danger',
        content: `Can't delete this property as it is associates with ${propertyAssociation.content}`
      });
      return;
    }
    const confirmMessage = propertyAssociation && propertyAssociation.isPropertyDeletable && propertyAssociation.content
      ? `This property associates with ${propertyAssociation.content}. Are you sure you want to delete this property?`
      : "Are you sure you want to delete this property?";
    if (confirm(confirmMessage)) {
      const t = loadingTrigger();
      deletePropertyById(property.id)
        .then(() => {
          addAlert({
            type: "success",
            content: "Property deleted successfully."
          });
          refreshPropertyList();
          history.push('/property');
        })
        .catch(alertApiErrors)
        .finally(() => loadingRelease(t));
    }
  }

  if (!property) {
    return null
  }

  return (
    <div className={`${style.container} property-show-page`}>

      <Tabs id="admin-loan-details-page-tabs">
        <Tab key={'overview'} eventKey={'overview'} title={'Overview'}>
          <Top propertyId={property.id} onDeleteClicked={onDeleteClicked} onEditClicked={onEditClicked}/>
          <section className="content">
            <PropertyInformationShow property={property}/>
            <PropertyDetailsShow propertyId={property.id} details={property.details}/>
            <PropertyTaxes propertyId={property.id}/>
            <AssociatedLoans propertyId={property.id}/>
            <PropertyDocuments propertyId={property.id}/>
            <LegalAddress
              filename={property.filename || 'No Name'}
              legalAddress={property.legalAddress}
              propertyId={property.id}
              hasLegalAddressDocument={property.hasLegalAddressDocument}
            />
          </section>
        </Tab>
        <Tab key={'insurance'} eventKey={'insurance'} title={'Insurance'}>
          <Top propertyId={property.id} onDeleteClicked={onDeleteClicked} onEditClicked={onEditClicked}/>
          <Insurance propertyId={property.id} aliasIds={property.associatedLoans}/>
        </Tab>
      </Tabs>
      <AddEditPropertyModal/>
      <DocumentUploadModal/>
      <AddEditTaxRecordModal/>
      <AddEditEntityModal/>
    </div>
  );
};

export default ShowPropertyModal;
