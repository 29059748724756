import {YesNoTextEnum} from "@interfaces/Enums";
import {PropertyFromData, PropertyServer} from "@interfaces/Property";
import {DocumentUpload} from "@interfaces/Document";
import {stringToDate} from "@common/basic";
import convertJsonToFormData from "@common/utils/form/convertJsonToFormData";
import {PropertyAcquisitionTypeEnum} from "@interfaces/GeneratedEnums";
import {SelectOptionProps} from "@components/forms/react-hook-form-bootstrap/interfaces";

export const convertPropertyToFormData = (propertyFromData: PropertyFromData) => {
  return convertJsonToFormData({
    ...propertyFromData,
    ownedBy: `${propertyFromData.owner?.value || ''}`,
    ownerCategory: propertyFromData.owner?.entityType && getOwnerCategory(propertyFromData.owner.entityType),
    counties: JSON.stringify(propertyFromData.mailingAddress.counties.map(county => county.value)),
    details: {
      ...propertyFromData.details,
      acquisitionType: propertyFromData.details.acquisitionType && `${propertyFromData.details.acquisitionType.value}`,
      isLeased: propertyFromData.details.isLeased && `${propertyFromData.details.isLeased === YesNoTextEnum.Yes}`
    }
  });
}

export const getAcquisitionTypeOption = (type: keyof typeof PropertyAcquisitionTypeMetadata | null): SelectOptionProps | null => {
  if (!type || !PropertyAcquisitionTypeMetadata[type]) {
    return null;
  }

  return {
    value: type,
    label: PropertyAcquisitionTypeEnum[type as keyof typeof PropertyAcquisitionTypeEnum],
    tooltip: PropertyAcquisitionTypeMetadata[type]
  };
};

export const convertDocumentToFormData = (requestData: DocumentUpload) => convertJsonToFormData(requestData);

export const convertPropertyServerToFormData = (propertyServer: PropertyServer) => {
  const {mailingAddress, details} = propertyServer
  const requestData: PropertyFromData = {
    propertyType: propertyServer.propertyType,
    numberOfProperties: `${propertyServer.numberOfProperties ?? ''}`,
    owner: propertyServer.owner,
    legalAddress: propertyServer.legalAddress,
    legalAddressDocument: null,
    lotSize: propertyServer.lotSize,
    finalProperty: propertyServer.finalProperty,
    initialProperty: propertyServer.initialProperty,
    asIsCondition: propertyServer.asIsCondition,
    otherFeature: propertyServer.otherFeature,
    accessInfo: propertyServer.accessInfo,
    mailingAddress: {
      address1: mailingAddress.address1,
      address2: mailingAddress.address2,
      address3: mailingAddress.address3,
      state: mailingAddress.state,
      city: mailingAddress.city,
      zip: mailingAddress.zip,
      longitude: mailingAddress.longitude,
      latitude: mailingAddress.latitude,
      counties: propertyServer.counties?.map(item => ({
        value: item.id,
        label: item.name
      }))
    },
    details: {
      purchasePrice: `${details?.purchasePrice ?? ''}`,
      purchaseDate: details?.purchaseDate ? stringToDate(details?.purchaseDate) : null,
      paidHardConstructionCost: `${details?.paidHardConstructionCost ?? ''}`,
      asIsValuation: `${details?.asIsValuation ?? ''}`,
      constructionBudget: `${details?.constructionBudget ?? ''}`,
      afterRepairValue: `${details?.afterRepairValue ?? ''}`,
      isLeased: details?.isLeased === null ? null : details?.isLeased ? YesNoTextEnum.Yes : YesNoTextEnum.No,
      monthlyMarketRent: `${details?.monthlyMarketRent ?? ''}`,
      monthlyHOADues: `${details?.monthlyHOADues ?? ''}`,
      annualTaxesOwed: `${details?.annualTaxesOwed ?? ''}`,
      yearlyInsurancePremium: `${details?.yearlyInsurancePremium ?? ''}`,
      soldPrice: `${details?.soldPrice ?? ''}`,
      soldDate: details?.soldDate ? stringToDate(details?.soldDate) : null,
      monthlyOtherExpenses: `${details?.monthlyOtherExpenses ?? ''}`,
      acquisitionType: getAcquisitionTypeOption(details?.acquisitionType || null),
    }
  };
  return requestData;
}
export const getOwnerCategory = (ownerCategory: string) => ownerCategory == "CONTACT" ? "INDIVIDUAL" : "ENTITY"

type IPropertyAcquisitionTypeMetadata = {
  [p in keyof typeof PropertyAcquisitionTypeEnum]: string;
};

export const PropertyAcquisitionTypeMetadata: IPropertyAcquisitionTypeMetadata = {
  PURCHASE: "The property was acquired using cash, mortgage, seller-financing, or other financing options.",
  FORECLOSURE: "The property was acquired by purchasing at a foreclosure auction.",
  DEED_IN_LIEU_OF_FORECLOSURE: "The property was acquired by receiving a deed directly from the borrower.",
  TAX_SALE: "The property was purchased at a tax sale when the previous owner failed to pay taxes.",
  EXCHANGE: "The property was acquired through a property exchange or swap.",
  INHERITANCE: "The property was passed down from a deceased person.",
  GIFT: "The property was given as a gift.",
  ADVERSE_POSSESSION: "In some cases, if someone occupies land for a certain time without the owner's permission, they can gain legal ownership through adverse possession.",
  LEASE_TO_OWN: "Some agreements allow tenants to purchase the property they are renting eventually.",
  CROWDFUNDING: "Investors can acquire shares in property through real estate crowdfunding platforms.",
};
